// Footer.styles.ts
import styled from "styled-components"
import { media } from "../../../../components/utils/media"
import { NavLink } from "react-router-dom"

export const FooterContainer = styled.footer`
  /* Mobile (base) styles */
  background: #141414;
  color: white;
  padding: 50px 20px;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  gap: 20px;
  font-size: 0.95rem;
  margin: auto;

  ${media.md`
    /* Tablet: from 768px up */
    grid-template-columns: 1fr 1fr;
    padding: 60px 40px;
    text-align: left;
    gap: 30px;
  `}

  ${media.lg`
    /* Desktop: from 1024px up */
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    gap: 40px;
    padding: 60px 40px;
  `}
`

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  ${media.md`
    align-items: flex-start;
  `}
`

export const FooterTitle = styled.h4`
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: inherit;
  text-transform: uppercase;
`

export const FooterTagline = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: inherit;
`

export const FooterLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`

export const FooterAddress = styled.p`
  font-weight: 500;
  color: inherit;
`

export const FooterEmail = styled.a`
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  transition: 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`

export const CopyrightContainer = styled.div`
  text-align: center;
  padding: 20px 0;
  background: #0d0d0c;
  font-size: 0.9rem;
  font-weight: 300;
  color: white;
`

export const TreesImage = styled.img`
  display: block;
  margin: auto;
  opacity: 0.9;
  max-width: 80px;

  ${media.md`
    display: none;
  `}
`
