import { Helmet } from "react-helmet-async"

import { GeneralSummary } from "./GeneralSummary"

import Hero from "./Hero"

import Team from "./Team"
import WhyChooseUs from "./WhyChooseUs"
import ServicesOverview from "./ServicesOverview"
import Testimonials from "./Testimonials"
import CTA from "./CTA"

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Clinique Dö | Acupuncture, Ostéopathie & Massothérapie à Rawdon –
          Soins thérapeutiques pour douleurs et bien-être
        </title>
        <meta
          name="description"
          content="Clinique Dö à Rawdon offre des soins thérapeutiques pour douleurs et bien-être. Acupuncture, ostéopathie, massothérapie et fasciathérapie dans Lanaudière. Prenez rendez-vous dès maintenant!"
        />
        <meta
          name="keywords"
          content="
          clinique dö, clinique do, clinique acupuncture rawdon, clinique ostéopathie rawdon, clinique massothérapie rawdon, clinique fasciathérapie rawdon, clinique acupuncture lanaudière, clinique ostéopathie lanaudière, clinique massothérapie lanaudière, clinique fasciathérapie lanaudière, acupuncture rawdon, ostéopathie rawdon, massothérapie rawdon, fasciathérapie rawdon, acupuncture lanaudière, ostéopathie lanaudière, massothérapie
        "
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://cliniquedo.com/" />
      </Helmet>

      <Hero />
      <GeneralSummary />
      <Team />
      <WhyChooseUs />
      <ServicesOverview />
      <Testimonials />
      <CTA />
      {/* <InteractiveCardsAndMovingImage />
      <Services />
      <AdBanner />
      <Faq />
      <Contact /> */}
    </>
  )
}

export default Home
