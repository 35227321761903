import styled from "styled-components"
import { motion } from "framer-motion"

/**
 * Outer Section: We make this tall enough (e.g. 300vh) for the pinned container to remain in view
 * while the user scrolls from 0% to 100% of this section.
 */
export const Section = styled.section`
  position: relative;
  background: white;
  width: 100%;
  height: 300vh; /* Adjust as needed for how long the pinned content stays on screen */
  /* Ensure no overflow constraints that might break sticky */
`

/**
 * PinnedContainer: Takes up exactly one viewport height and stays pinned at top: 0.
 * "sticky" means it remains in place while the user scrolls inside the Section.
 */
export const PinnedContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;

  /* Layout for content: left col (text) + right col (images) */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6rem 2rem;
  box-sizing: border-box;
`

export const LeftCol = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4rem;
`

export const RightCol = styled.div`
  width: 40%;
`

/* Grid for multiple images on the right side */
export const ImageGrid = styled(motion.div)`
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 1rem;
  max-width: 400px;

  @media (min-width: 1024px) {
    max-width: unset;
    width: 90%;
  }
`

export const ImageWrapper = styled(motion.div)`
  overflow: hidden;
  border-radius: 12px;

  img {
    width: 100%;
    display: block;
    border-radius: 12px;
    filter: grayscale(100%);
  }
`

export const Heading = styled(motion.h2)`
  font-size: 2.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText};
  /* margin-bottom: 1rem; */
`

export const Paragraph = styled(motion.p)`
  font-size: 1.4rem;
  color: #333;
  max-width: 500px;
  line-height: 1.5;
  margin-bottom: 1.5rem;
`
