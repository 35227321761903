import React from "react"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"

// --- HERO SECTION ---
const HeroSection = styled.section`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(223, 145, 98, 0.2), rgba(223, 145, 98, 0.4)),
    url("/img/hero-about.jpg") center/cover no-repeat;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  margin-top: 15vh;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
    margin-top: 10vh;
  }
`

const HeroContent = styled.div`
  max-width: 800px;

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  }

  p {
    color: white;
    font-size: 1.5rem;
    line-height: 1.8;
    opacity: 0.9;
    font-weight: 500;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

// --- CONTENT SECTIONS ---
const Section = styled.section`
  width: 100%;
  padding: 6rem 2rem;
  background: ${({ background }) => background || "white"};

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.7;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  height: 350px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    height: 280px;
  }
`

// --- ABOUT PAGE COMPONENT ---
const About = () => {
  return (
    <>
      <Helmet>
        <title>
          À propos de Clinique Dö | Acupuncture, Ostéopathie & Massothérapie à
          Rawdon
        </title>
        <meta
          name="description"
          content="Découvrez l’histoire, la mission et l’équipe de la Clinique Dö à Rawdon. Nous offrons des soins thérapeutiques en acupuncture, ostéopathie, massothérapie et fasciathérapie."
        />
        <meta
          name="keywords"
          content="
        clinique dö, clinique do, clinique acupuncture rawdon, clinique ostéopathie rawdon, clinique massothérapie rawdon, clinique fasciathérapie rawdon, clinique acupuncture lanaudière, clinique ostéopathie lanaudière, clinique massothérapie lanaudière, clinique fasciathérapie lanaudière, acupuncture rawdon, ostéopathie rawdon, massothérapie rawdon, fasciathérapie rawdon, acupuncture lanaudière, ostéopathie lanaudière, massothérapie
       "
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://cliniquedo.com/a-propos" />
      </Helmet>

      {/* HERO */}
      <HeroSection>
        <HeroContent>
          <h1>Des professionnels de la santé engagés pour votre bien-être</h1>
          <p>
            Depuis 2008, nous accompagnons nos patients avec des soins adaptés
            et axés sur des résultats concrets.
          </p>
        </HeroContent>
      </HeroSection>

      {/* CONTENT SECTIONS */}
      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Notre mission</h2>
              <p>
                Vous offrir des soins thérapeutiques efficaces, personnalisés et
                complémentaires à la médecine conventionnelle.
              </p>
            </TextContent>
            <ImageWrapper>
              <img src="/img/about-us1.jpg" alt="Notre mission" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>À propos de l’équipe</h2>
              <p>
                Notre équipe est composée de thérapeutes passionnés ayant
                exploré divers domaines, enrichissant ainsi leur approche et
                leur pratique. Trois d’entre nous ont enseigné l’ostéopathie et
                la thérapie manuelle (Michel Venne D.O.). Un autre s’est
                spécialisé dans le Tuina* (Stéphane Turbide Ac), transmettant
                l’art subtil de cette médecine traditionnelle chinoise.
                <br />
                <br />
                Une autre encore partage sa pratique du yoga (Nathalia Levac),
                alliant mouvement et conscience pour un bien-être profond.
                Ensemble, nous avons à cœur d’accompagner nos patients avec une
                vision holistique et une expertise complémentaire.
              </p>
            </TextContent>
            <ImageWrapper>
              <img src="/img/about-us2.jpg" alt="Notre équipe" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>À propos de Dö</h2>
              <p>
                En 2008, la clinique Dö est née de la passion et de l’expertise
                de deux thérapeutes engagés Stefan Pelecahty, Ac. & Michel Venne
                D.O.. Alliant l’acupuncture et l’ostéopathie, ils ont fait
                évoluer un espace dédié au bien-être et à la santé globale.
                <br />
                <br />
                En 2013, une cellule Dö grandit dans Lanaudière à Rawdon où
                Stefan s’établit avec sa famille. En 2021, la clinique Dö Rawdon
                ouvre ses portes sur la rue Albert, avec une équipe en pleine
                expansion.
              </p>
            </TextContent>
            <ImageWrapper>
              <img src="/img/rawdon-clinique.png" alt="Clinique Dö" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Une philosophie d’excellence</h2>
              <p>
                Le choix du nom Dö s’inspire du "Dō" philosophique, signifiant
                "la voie", symbole d’un apprentissage et d’un perfectionnement
                constant.
                <br />
                <br />
                Comme dans les arts martiaux, la clinique incarne cette quête
                d’évolution, de perfectionnement et d’excellence. Depuis sa
                création, Dö est un lieu de soin et de progression, dédié au
                bien-être et à la santé durable de ses patients.
                <br />
                <br />
                Les 5 points au-dessus du “O” représentent la théorie des 5
                éléments, une grille d’évaluation fondamentale en médecine
                chinoise. Cette symbolique reflète des siècles de connaissances
                et d’observations.
              </p>
            </TextContent>
            <ImageWrapper>
              <img src="/img/about-us3.jpg" alt="Philosophie Dö" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>
    </>
  )
}

export default About
