import React, { useEffect } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import useAuthStore from "../../features/auth/authStore.client"
import { useDelayedLoading } from "../hooks/useDelayedLoading"
import Loader from "../base/Loader"
import logger from "./clientLogger"

interface PrivateRouteProps {
  requiredRole?: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ requiredRole }) => {
  const location = useLocation()

  const user = useAuthStore((state) => state.user)
  const loading = useAuthStore((state) => state.loading)
  const checkAuth = useAuthStore((state) => state.checkAuth)
  const logout = useAuthStore((state) => state.logout)

  const showLoader = useDelayedLoading(loading)

  useEffect(() => {
    // Skip the auth check if the user is on the login page
    if (location.pathname !== "/acces-client") {
      try {
        checkAuth()
      } catch (error) {
        logger.error("Error checking authentication", { error })
        logout()
      }
    }
  }, [location.pathname, checkAuth, logout])

  if (showLoader) {
    return <Loader variant="fullScreen" />
  }

  if (!user) {
    // Preserve the current location so we can redirect back after login
    return <Navigate to="/acces-client" state={{ from: location }} />
  }

  if (requiredRole && user.role !== requiredRole) {
    return <Navigate to="/unauthorized" /> // Redirect if role is not allowed
  }

  return <Outlet /> // Render the child routes
}

export default PrivateRoute
