import { Helmet } from "react-helmet-async"
import styled from "styled-components"

const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  background: url("/img/terms-bg.jpg") center/cover no-repeat;
  background: ${({ theme }) => theme.primaryLight};
  color: white;
  text-align: center;
`

// const Overlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.6);
//   z-index: 0;
// `

const Content = styled.div`
  position: relative;
  z-index: 2;
  max-width: 800px;
  width: 100%;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 20px;
`

const Text = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 20px;
`

const TermsOfUse = () => {
  return (
    <>
      <Helmet>
        <title>Clinique Dö | Conditions d’utilisation</title>
        <meta
          name="description"
          content="Découvrez les conditions d’utilisation de Clinique Dö. Apprenez comment nous utilisons notre service et les règles à suivre pour garantir un environnement sûr et fiable."
        />
        <meta
          name="keywords"
          content="conditions d’utilisation, clinique dö, termes et conditions, règles, sécurité"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://cliniquedo.com/conditions-utilisation"
        />
      </Helmet>

      <Layout>
        {/* <Overlay /> */}
        <Content>
          <Title>Conditions d’utilisation</Title>
          <Text>
            En utilisant notre service, vous acceptez nos termes et conditions.
            Ces règles visent à garantir un environnement sûr et fiable pour
            tous nos utilisateurs.
          </Text>
          <Text>
            Nous nous réservons le droit de modifier ces conditions à tout
            moment.
          </Text>
        </Content>
      </Layout>
    </>
  )
}

export default TermsOfUse
