import React from "react"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"

// --- HERO SECTION ---
const HeroSection = styled.section`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(50, 50, 50, 0.4), rgba(50, 50, 50, 0.6)),
    url("/img/hero-privacy-policy.jpg") center/cover no-repeat;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  margin-top: 15vh;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
    margin-top: 10vh;
  }
`

const HeroContent = styled.div`
  max-width: 900px;

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  }

  p {
    color: white;
    font-size: 1.4rem;
    line-height: 1.8;
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

// --- CONTENT SECTIONS ---
const Section = styled.section`
  width: 100%;
  padding: 6rem 2rem;
  background: ${({ background }) => background || "white"};

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.7;
  }
`

// const ImageWrapper = styled.div`
//   flex: 1;
//   max-width: 500px;
//   height: 350px;
//   border-radius: 12px;
//   overflow: hidden;
//   box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08);

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

//   @media (max-width: 768px) {
//     max-width: 100%;
//     height: 280px;
//   }
// `

// --- PRIVACY POLICY PAGE COMPONENT ---
const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Clinique Dö | Politique de Confidentialité</title>
        <meta
          name="description"
          content="Découvrez la politique de confidentialité de Clinique Dö. Apprenez comment nous collectons, utilisons et protégeons vos informations personnelles."
        />
        <meta
          name="keywords"
          content="politique de confidentialité, clinique dö, protection des données, informations personnelles, confidentialité, sécurité des données"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://cliniquedo.com/politique-de-confidentialite"
        />
      </Helmet>

      {/* HERO */}
      <HeroSection>
        <HeroContent>
          <h1>Politique de confidentialité</h1>
          <p>
            Votre vie privée est notre priorité. Découvrez comment nous
            collectons, utilisons et protégeons vos informations.
          </p>
        </HeroContent>
      </HeroSection>

      {/* CONTENT SECTIONS */}
      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>1. Informations collectées</h2>
              <p>
                Nous collectons certaines informations personnelles lorsque vous
                utilisez nos services, notamment :
                <br />
                📍 Nom, prénom, adresse e-mail et numéro de téléphone fournis
                lors de la prise de rendez-vous.
                <br />
                📍 Informations de paiement sécurisées (gérées via des
                prestataires tiers comme Stripe).
                <br />
                📍 Données de navigation via des cookies et technologies
                similaires pour améliorer votre expérience.
              </p>
            </TextContent>
            {/* <ImageWrapper>
              <img src='/img/privacy-collect.jpg' alt='Informations collectées' />
            </ImageWrapper> */}
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>2. Utilisation de vos données</h2>
              <p>
                Nous utilisons vos données uniquement pour :
                <br />
                ✔ Gérer vos rendez-vous et assurer le suivi des services.
                <br />
                ✔ Améliorer l’expérience utilisateur et personnaliser notre
                communication.
                <br />✔ Se conformer aux obligations légales et de sécurité.
              </p>
            </TextContent>
            {/* <ImageWrapper>
              <img src='/img/privacy-use.jpg' alt='Utilisation des données' />
            </ImageWrapper> */}
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>3. Protection et stockage</h2>
              <p>
                🔒 Toutes vos données sont stockées sur des serveurs sécurisés
                avec des protocoles de cryptage avancés.
                <br />
                🛡️ Nous ne partageons jamais vos données sans votre
                consentement, sauf en cas d'obligation légale.
              </p>
            </TextContent>
            {/* <ImageWrapper>
              <img src='/img/privacy-security.jpg' alt='Sécurité des données' />
            </ImageWrapper> */}
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>4. Cookies et technologies similaires</h2>
              <p>
                Nous utilisons des cookies pour :
                <br />
                🍪 Analyser le trafic et améliorer votre expérience utilisateur.
                <br />
                🍪 Personnaliser nos contenus et offres.
                <br />
                📌 Vous pouvez gérer vos préférences de cookies à tout moment
                dans les paramètres de votre navigateur.
              </p>
            </TextContent>
            {/* <ImageWrapper>
              <img src='/img/privacy-cookies.jpg' alt='Cookies et technologies' />
            </ImageWrapper> */}
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>5. Vos droits</h2>
              <p>
                📌 Accéder à vos données personnelles et les modifier.
                <br />
                📌 Demander la suppression ou la portabilité de vos
                informations.
                <br />
                📌 Modifier vos préférences de communication et de cookies.
              </p>
              {/* <CTAButton href='/contact'>Contactez-nous pour exercer vos droits →</CTAButton> */}
            </TextContent>
            {/* <ImageWrapper>
              <img src='/img/privacy-rights.jpg' alt='Vos droits' />
            </ImageWrapper> */}
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>6. Contact et mises à jour</h2>
              <p>
                Pour toute question relative à notre politique de
                confidentialité :
                <br />
                📧 Contactez-nous à <strong>info@cliniquedo.com</strong>
                <br />
                📅 Cette politique est mise à jour régulièrement pour refléter
                les évolutions légales et techniques.
              </p>
            </TextContent>
            {/* <ImageWrapper>
              <img src='/img/privacy-contact.jpg' alt='Contact' />
            </ImageWrapper> */}
          </ContentBlock>
        </ContentWrapper>
      </Section>
    </>
  )
}

export default PrivacyPolicy
