import React from "react"
import styled from "styled-components"

// Styled Components
const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 2rem;
  background: url("/img/acupuncture-session2.jpg") center/cover no-repeat;
  width: 100%;
  position: relative;
  overflow: hidden;
  color: white;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  position: relative;
  z-index: 1;
  gap: 4rem;

  @media (max-width: 960px) {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
`

const LeftContent = styled.div`
  flex: 1;
  max-width: 600px;

  @media (max-width: 960px) {
    max-width: 100%;
  }
`

const RightContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  @media (max-width: 960px) {
    align-items: center;
  }
`

const Title = styled.h2`
  font-size: 3rem;
  color: #fff;
  color: ${({ theme }) => theme.primaryLight};
  margin-bottom: 1rem;
  letter-spacing: -0.5px;

  @media (max-width: 960px) {
    font-size: 2.5rem;
  }
`

const BookingText = styled.p`
  font-size: 1.3rem;
  color: #f5f5f5;
  margin-bottom: 1.5rem;
  line-height: 1.6;

  @media (max-width: 960px) {
    font-size: 1.2rem;
  }
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  font-size: 1.1rem;
  color: #fff;
  font-weight: 500;
`

const ContactItem = styled.div`
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.15);
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  backdrop-filter: blur(6px);
  transition: all 0.3s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: 960px) {
    flex-direction: column;
    width: 100%;
  }
`

const CTAButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 220px;
  text-align: center;

  &:hover {
    transform: translateY(-3px);
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`

const PrimaryButton = styled(CTAButton)`
  background: #ffb385;
  color: #fff;
  width: 100%;
  &:hover {
    background: #e69363;
  }
`

const SecondaryButton = styled(CTAButton)`
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.4);
  width: 100%;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.6);
  }
`

const CTA = () => {
  return (
    <Section>
      <ContentWrapper>
        {/* Left Column */}
        <LeftContent>
          <Title>Prêt à commencer votre traitement ?</Title>
          <BookingText>
            Prenez soin de votre corps et de votre esprit dès aujourd’hui.
          </BookingText>
          <ContactInfo>
            <ContactItem>514-722-1641</ContactItem>
            <ContactItem>Rawdon (Lanaudière)</ContactItem>
            <ContactItem>
              Réservez votre rendez-vous en ligne en quelques clics.
            </ContactItem>
            <ButtonsWrapper>
              <PrimaryButton href="/nous-joindre">
                Prendre rendez-vous
              </PrimaryButton>
              <SecondaryButton href="/nous-joindre#appeler">
                Poser une question
              </SecondaryButton>
            </ButtonsWrapper>
          </ContactInfo>
        </LeftContent>

        {/* Right Column - Buttons */}
        <RightContent></RightContent>
      </ContentWrapper>
    </Section>
  )
}

export default CTA
