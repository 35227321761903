import Home from "../pages/website/Home/index.server" // Static component (server component)
import PrivateRoute from "../components/utils/PrivateRoute.client" // Interactive wrapper
import Dashboard from "../pages/webapp/Dashboard/index.client" // Interactive component
import TermsOfUse from "../pages/website/TermsOfUse/index.server"
import Resources from "../pages/website/Resources/index.server"
import PrivacyPolicy from "../pages/website/PrivacyPolicy/index.server"

import Contact from "../pages/website/Contact/index.server"
import Services from "../pages/website/services/ServicesPage/index.server"
import About from "../pages/website/About/index.server"
import ClientLogin from "../pages/website/auth/ClientLogin/index.server"
import Acupuncture from "@pages/website/services/Acupuncture"
import MassageTherapy from "@pages/website/services/MassageTherapy"
import Osteopathy from "@pages/website/services/Osteopathy"
import BachFlowers from "@pages/website/services/BachFlowers"

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/a-propos",
    element: <About />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/services/acupuncture",
    element: <Acupuncture />,
  },
  {
    path: "/services/osteopathie",
    element: <Osteopathy />,
  },
  {
    path: "/services/fasciatherapie-massotherapie",
    element: <MassageTherapy />,
  },
  {
    path: "/services/fleurs-de-bach",
    element: <BachFlowers />,
  },
  {
    path: "/nous-joindre",
    element: <Contact />,
  },
  {
    path: "/acces-client",
    element: <ClientLogin />,
  },
  {
    path: "/conditions-utilisation",
    element: <TermsOfUse />,
  },
  {
    path: "/politique-de-confidentialite",
    element: <PrivacyPolicy />,
  },
  {
    path: "/ressources-utiles",
    element: <Resources />,
  },
  {
    path: "/app",
    element: <PrivateRoute requiredRole="ADMIN" />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
]

export default routes
