/* logger for runtime error handling */

/**
 * LogLevel represents the various levels of logging.
 */
export type LogLevel = "debug" | "info" | "warn" | "error"

/**
 * LoggerOptions defines optional configuration for the client logger.
 */
export interface LoggerOptions {
  /**
   * When true, logs in production are forwarded to a remote logging service.
   */
  remoteLoggingEnabled?: boolean
  /**
   * An optional remote logging service instance.
   */
  remoteLoggingService?: RemoteLoggingService
}

/**
 * RemoteLoggingService defines an interface for a remote logging service.
 */
export interface RemoteLoggingService {
  /**
   * Sends a log message to a remote service.
   * @param level The log level.
   * @param message The log message.
   * @param meta Optional metadata associated with the log.
   */
  sendLog: (
    level: LogLevel,
    message: string,
    meta?: Record<string, unknown>,
  ) => void
}

/**
 * A default remote logging service that does nothing.
 * Replace this with an actual integration (e.g. Sentry, LogRocket) as needed.
 */
const defaultRemoteLoggingService: RemoteLoggingService = {
  sendLog: (
    level: LogLevel,
    message: string,
    meta?: Record<string, unknown>,
  ) => {
    // Default implementation does nothing.
    // Example: Sentry.captureException(new Error(message));
  },
}

/**
 * ClientLogger is a utility class that provides environment-aware logging.
 *
 * In development, logs are printed to the console.
 * In production, verbose logging can be suppressed or forwarded to a remote logging service.
 */
class ClientLogger {
  private isDevelopment: boolean
  private options: LoggerOptions

  constructor(options?: LoggerOptions) {
    this.isDevelopment = process.env.NODE_ENV === "development"
    this.options = {
      remoteLoggingEnabled: false,
      remoteLoggingService: defaultRemoteLoggingService,
      ...options,
    }
  }

  /**
   * Logs a debug message.
   * Debug logs are output only in development.
   *
   * @param message The debug message.
   * @param meta Optional metadata.
   */
  public debug(message: string, meta?: Record<string, unknown>): void {
    if (this.isDevelopment) {
      console.debug(`[DEBUG - ${new Date().toISOString()}]: ${message}`, meta)
    }
  }

  /**
   * Logs an info message.
   *
   * In development, the message is printed to the console.
   * In production, if remote logging is enabled, the message is forwarded.
   *
   * @param message The info message.
   * @param meta Optional metadata.
   */
  public info(message: string, meta?: Record<string, unknown>): void {
    if (this.isDevelopment) {
      console.info(`[INFO  - ${new Date().toISOString()}]: ${message}`, meta)
    } else if (this.options.remoteLoggingEnabled) {
      this.options.remoteLoggingService?.sendLog("info", message, meta)
    }
  }

  /**
   * Logs a warning message.
   *
   * In development, the message is printed to the console.
   * In production, if remote logging is enabled, the message is forwarded.
   *
   * @param message The warning message.
   * @param meta Optional metadata.
   */
  public warn(message: string, meta?: Record<string, unknown>): void {
    if (this.isDevelopment) {
      console.warn(`[WARN  - ${new Date().toISOString()}]: ${message}`, meta)
    }
    if (!this.isDevelopment && this.options.remoteLoggingEnabled) {
      this.options.remoteLoggingService?.sendLog("warn", message, meta)
    }
  }

  /**
   * Logs an error message.
   *
   * In development, the message is printed to the console.
   * In production, if remote logging is enabled, the error is forwarded.
   *
   * @param message The error message.
   * @param meta Optional metadata.
   */
  public error(message: string, meta?: Record<string, unknown>): void {
    if (this.isDevelopment) {
      console.error(`[ERROR - ${new Date().toISOString()}]: ${message}`, meta)
    }
    if (!this.isDevelopment && this.options.remoteLoggingEnabled) {
      this.options.remoteLoggingService?.sendLog("error", message, meta)
    }
  }
}

/**
 * Export a default instance of the ClientLogger.
 *
 * The default instance is configured to enable remote logging only in production.
 * To integrate with an actual remote logging service, pass an instance of RemoteLoggingService
 * via options.
 */
const logger = new ClientLogger({
  remoteLoggingEnabled: process.env.NODE_ENV === "production",
  // Optionally replace with your remote logging service:
  // remoteLoggingService: myRemoteLoggingServiceInstance,
})

export default logger
