import { useState, useEffect } from "react"

export function useDelayedLoading(isLoading: boolean, delay = 300) {
  const [showLoader, setShowLoader] = useState(isLoading)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (isLoading) {
      setShowLoader(true)
    } else {
      // Keep showing loader for at least the delay duration
      timeout = setTimeout(() => {
        setShowLoader(false)
      }, delay)
    }
    return () => clearTimeout(timeout)
  }, [isLoading, delay])

  return showLoader
}
