import { create } from "zustand"
import themeSettings, { buildTheme } from "./theme-settings"
import { persist, createJSONStorage } from "zustand/middleware"
import { DefaultTheme } from "styled-components"

interface ThemeState {
  theme: DefaultTheme
  themeName: "light" | "dark"
  toggleTheme: () => void
  setTheme: (themeName: "light" | "dark") => void
  setCustomTheme: (customColors: Partial<DefaultTheme>) => void
}

const useThemeStore = create<ThemeState, [["zustand/persist", unknown]]>(
  persist(
    (
      set,
      //  get
    ) => ({
      theme: buildTheme("light"),
      themeName: "light",
      toggleTheme: () =>
        set((state) => {
          const newThemeName = state.themeName === "light" ? "dark" : "light"
          return {
            theme: themeSettings[newThemeName] as DefaultTheme,
            themeName: newThemeName,
          }
        }),
      setTheme: (themeName: "light" | "dark") =>
        set(() => ({
          theme: themeSettings[themeName] as DefaultTheme,
          themeName,
        })),
      setCustomTheme: (customColors: Partial<DefaultTheme>) =>
        set((state) => ({
          theme: {
            ...themeSettings[state.themeName],
            ...customColors,
          } as DefaultTheme,
        })),
    }),
    {
      name: "theme-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useThemeStore
