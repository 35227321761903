import React from "react"
import styled from "styled-components"
import { create } from "zustand"

// Define the type for our store's state.
type DashboardState = {
  counter: number
  increment: () => void
}

// Create a Zustand store for the dashboard (e.g., a simple counter).
const useDashboardStore = create<DashboardState>((set) => ({
  counter: 0,
  increment: () => set((state) => ({ counter: state.counter + 1 })),
}))

// Styled components for layout.
const Container = styled.div`
  padding: 2rem;
  margin-top: 14.5vh;
  text-align: center;
  font-family: sans-serif;
`

const CounterDisplay = styled.div`
  font-size: 2rem;
  margin: 1rem 0;
`

const Button = styled.button`
  background-color: #0070f3;
  border: none;
  color: #fff;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #005bb5;
  }
`

const Dashboard: React.FC = () => {
  const { counter, increment } = useDashboardStore()

  return (
    <Container>
      {counter >= 10 && <h1>🎉 You won infinity laaaauv 🎉</h1>}
      {counter < 10 && (
        <>
          <h1>Dashboard</h1>
          <p>
            Welcome to your dashboard Honey! Click on the counter below until
            there's enough love:
          </p>
          <CounterDisplay>❤️ Counter: {counter}</CounterDisplay>
          <Button onClick={increment}>+1</Button>
        </>
      )}
    </Container>
  )
}

export default Dashboard
