import styled from "styled-components"
import Cardiogram from "@assets/icons/cardiogram.svg"
import Cardiology from "@assets/icons/cardiology.svg"
import ClinicHistory from "@assets/icons/clinic-history.svg"
import Pharmacy from "@assets/icons/pharmacy.svg"
import Stethoscope from "@assets/icons/stethoscope.svg"

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #fdfaf7;
  background-color: white;

  @media (min-width: 768px) {
    padding: 3rem 1rem;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
    border-radius: 16px;
    filter: grayscale(100%);
  }
`

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  border-radius: 56px;

  @media (max-width: 768px) {
    border-radius: 16px;
  }
`

const OverlayText = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  color: ${(props) => props.theme.primaryLight};
  font-size: 2rem;
  max-width: 15rem;
  font-weight: 500;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: left;
  margin-left: 2rem;

  &::before {
    content: "";
    display: block;
    width: 180px;
    height: 3px;
    background-color: ${(props) => props.theme.primaryLight};
    margin-top: 10px;
    margin-right: auto;
    transform: translate(0rem, -1.3rem);
  }

  @media (min-width: 768px) {
    bottom: 4rem;
    left: 4rem;
    font-size: 3rem;
    max-width: 30rem;
    margin-left: 4rem;

    &::before {
      width: 560px;
      transform: translate(5.2rem, -1.3rem);
      margin-right: unset;
    }
  }
`

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: 900px;
  }
`

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: white;
  background-color: #f7f7f7;
  /* background: ${(props) => props.theme.primaryLight}; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease-in-out;

  * {
    color: ${(props) => props.theme.primaryDark};
  }

  &:hover {
    transform: translateY(-3px);
  }

  svg {
    font-size: 2.8rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;
    padding: 1.5rem;
    grid-template-columns: 1fr 6fr;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.primaryLight}50;
  color: white;
  border-radius: 12px;
  padding: 1rem 0;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

const FeatureText = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 2rem;
    /* font-weight: bold; */

    margin: 0;
  }

  p {
    font-size: 1.1rem;
    color: #666 !important;
    margin-top: 4px;
  }
`

const features = [
  {
    icon: <Cardiogram />,
    title: "Une approche clinique et personnalisée",
    description:
      "Nos soins sont adaptés à votre condition spécifique pour des résultats concrets.",
  },
  {
    icon: <Cardiology />,
    title: "Une équipe de professionnels qualifiés",
    description:
      "Acupuncteurs, ostéopathes et massothérapeutes expérimentés, reconnus et certifiés.",
  },
  {
    icon: <ClinicHistory />,
    title: "Une prise de rendez-vous rapide et flexible",
    description:
      "Disponible en ligne ou par téléphone, selon vos disponibilités.",
  },
  {
    icon: <Pharmacy />,
    title: "Deux cliniques à votre service",
    description: "À Rosemont (Montréal) et Rawdon (Lanaudière).",
  },
  {
    icon: <Stethoscope />,
    title: "Traitements reconnus par la plupart des assurances",
    description:
      "Recevez un reçu pour vos soins (incluant CNESST, SAAQ, IVAC).",
  },
]

const WhyChooseUs = () => {
  return (
    <Section>
      <ImageContainer>
        <img src="/img/rawdon-clinique.png" alt="Clinique Do" />
        <Overlay>
          <OverlayText>Pourquoi choisir la Clinique dö ?</OverlayText>
        </Overlay>
      </ImageContainer>

      {/* Features List */}
      <FeaturesContainer>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <Icon> {feature.icon}</Icon>
            <FeatureText>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </FeatureText>
          </FeatureCard>
        ))}
      </FeaturesContainer>
    </Section>
  )
}

export default WhyChooseUs
