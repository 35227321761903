import React from "react"
import {
  FooterContainer,
  FooterColumn,
  FooterTitle,
  FooterTagline,
  FooterLink,
  FooterAddress,
  FooterEmail,
  CopyrightContainer,
} from "./Style"
import Logo from "../../../../app/assets/Logo.svg"

const Footer: React.FC = () => {
  return (
    <>
      <FooterContainer>
        {/* Left – Brand Info */}
        <FooterColumn>
          <Logo />
          <FooterTitle>Clinique Dö</FooterTitle>
          <FooterTagline>
            Soins thérapeutiques pour douleurs et bien-être
          </FooterTagline>
          <FooterAddress>
            3636 Rue Albert, Rawdon
            <br /> QC J0K 1S0
          </FooterAddress>
          <FooterAddress>
            3884 Rue de Bellechasse, Montréal
            <br /> QC H1X 1J4
          </FooterAddress>
          <FooterEmail href="mailto:info@cliniquedo.com">
            info@cliniquedo.com
          </FooterEmail>
        </FooterColumn>

        {/* Quick Links */}
        <FooterColumn>
          <FooterTitle>Liens rapides</FooterTitle>
          <FooterLink to="/#accueil">Accueil</FooterLink>
          <FooterLink to="/a-propos">À propos</FooterLink>
          <FooterLink to="/services">Services</FooterLink>
          {/* <FooterLink to='/#faq'>FAQ</FooterLink>
          <FooterLink to='/urgence'>Urgence</FooterLink> */}
          <FooterLink to="/politique-de-confidentialite">
            Politique de confidentialité
          </FooterLink>
          <FooterLink to="/conditions-utilisation">
            Conditions d'utilisation
          </FooterLink>
        </FooterColumn>

        {/* Services & Tarifs */}
        <FooterColumn>
          <FooterTitle>Services</FooterTitle>
          <FooterLink to="/services">Tous nos services</FooterLink>
          <FooterLink to="/services/acupuncture">Acupuncture</FooterLink>
          <FooterLink to="/services/osteopathie">Ostéopathie</FooterLink>
          <FooterLink to="/services/fasciatherapie-massotherapie">
            Massotherapie & Fasciathérapie
          </FooterLink>
          <FooterLink to="/services/fleurs-de-bach">Fleurs de Bach</FooterLink>
        </FooterColumn>

        {/* Online Access & Territories */}
        <FooterColumn>
          <FooterTitle>Accès en Ligne</FooterTitle>
          <FooterLink to="/app">Accès client</FooterLink>
          {/* <FooterLink to='/paiement-en-ligne'>Paiement en ligne</FooterLink> */}
          {/* <FooterLink to='/formulaires'>Formulaires</FooterLink> */}

          <FooterTitle>Par territoire</FooterTitle>
          <FooterLink to="/services">Rawdon</FooterLink>
          <FooterLink to="/services">Montreal</FooterLink>
          <FooterLink to="/services">Lanaudière</FooterLink>
        </FooterColumn>
      </FooterContainer>

      {/* Copyright Section */}
      <CopyrightContainer>
        © 2025 Clinique Dö |{" "}
        <a
          href="https://www.sor-seo.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "white",
            textDecoration: "none",
            fontWeight: "600",
            transition: "0.3s ease",
          }}
        >
          Design & Développement par SOR SEO
        </a>
      </CopyrightContainer>
    </>
  )
}

export default Footer
