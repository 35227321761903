import React from "react"
import styled from "styled-components"
import LoaderInfinity from "./LoaderInfinity"

// CENTERED LOADER

interface LoaderProps {
  /** 'fullScreen' makes the loader cover the viewport (minus header if provided), 
      'container' makes it fill its parent container */
  variant?: "fullScreen" | "container"
  /** Optional header height (for fullScreen variant). Defaults to '0px' if not provided. */
  headerHeight?: string
  /** Optional background color override. Falls back to theme.backgroundPrimary. */
  backgroundColor?: string
}

const LoaderWrapper = styled.div<LoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.backgroundPrimary || "#F9F9F7"};
  ${(props) =>
    props.variant === "fullScreen"
      ? `
    width: 100vw;
    height: calc(100vh - ${props.headerHeight || "0px"});
  `
      : `
    width: 100%;
    height: 100%;
  `}
`

const Loader: React.FC<LoaderProps> = ({
  variant = "container",
  headerHeight,
  backgroundColor,
}) => {
  return (
    <LoaderWrapper
      variant={variant}
      headerHeight={headerHeight}
      backgroundColor={backgroundColor}
    >
      <LoaderInfinity />
    </LoaderWrapper>
  )
}

export default Loader
