import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"

import routes from "./routes"
import useThemeStore from "./theme/useThemeStore"
import { GlobalStyle } from "./theme"
import { Header } from "../pages/website/Common/Header"
import Footer from "../pages/website/Common/Footer"
import AppLayout from "../components/layout/AppLayout"
import { ScrollToTop } from "../components/utils/scrollToTop"
import { EntryModal } from "../pages/website/EntryModal"
import CookiePopup from "@components/base/CookieConsent"

export interface AppProps {
  isAuthenticated: boolean
  url: string
  // Optionally pass a custom Router component (e.g. StaticRouter for SSR)
  Router?: React.ComponentType<{ children: React.ReactNode }>
  // Optionally pass router-specific props (for SSR, e.g., location, context)
  routerProps?: Record<string, unknown>
}

export const App: React.FC<AppProps> = ({
  // isAuthenticated,
  // url,
  Router = BrowserRouter,
  routerProps = {},
}) => {
  const { theme } = useThemeStore()

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <CookiePopup />

      <Router {...routerProps}>
        <EntryModal />

        <ScrollToTop />
        <Header />
        <AppLayout>
          <Routes>
            {routes.map(({ path, element, children, ...rest }, index) => (
              <Route key={index} path={path} element={element} {...rest}>
                {children &&
                  children.map(
                    (
                      { path: childPath, element: childElement, ...childRest },
                      childIndex,
                    ) => (
                      <Route
                        key={childIndex}
                        path={childPath}
                        element={childElement}
                        {...childRest}
                      />
                    ),
                  )}
              </Route>
            ))}
          </Routes>
        </AppLayout>
        <Footer />
      </Router>
    </ThemeProvider>
  )
}

export default App
