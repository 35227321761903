import { useEffect } from "react"
import { useLocation } from "react-router-dom"

// To handle same‑page hash links smoothly. Also re‑scroll after route changes if needed.

export function useSmoothScrolling() {
  const location = useLocation()

  // After a route change, scroll to hash if present
  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const section = document.querySelector(location.hash)
        if (section) {
          const offset = 85 // adjust if needed
          const sectionPosition =
            section.getBoundingClientRect().top + window.scrollY
          const offsetPosition = sectionPosition + offset
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          })
        }
      }, 100)
    }
  }, [location])

  /**
   * If the link is a same-page anchor, prevent default and scroll.
   * If cross-page, normal navigation triggers and scrolling occurs after route change.
   */
  const handleSmoothScroll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    target: string,
    closeMenuCallback?: () => void,
  ) => {
    const [targetPath, targetHash] = target.split("#")
    const hash = targetHash ? `#${targetHash}` : null
    if (hash) {
      // same-page link
      if (!targetPath || targetPath === location.pathname) {
        event.preventDefault()
        const section = document.querySelector(hash)
        if (section) {
          const offset = -20
          const sectionPosition =
            section.getBoundingClientRect().top + window.scrollY
          const offsetPosition = sectionPosition - offset
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          })
        }
        if (closeMenuCallback) closeMenuCallback()
      } else {
        // cross-page link, let the route change happen
        if (closeMenuCallback) closeMenuCallback()
      }
    }
    if (closeMenuCallback) closeMenuCallback()
  }

  return { handleSmoothScroll }
}
