import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

// Styled Components
const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
  background: #fdfaf7;
  width: 100%;
`

const Title = styled.h2`
  font-size: 3rem;
  /* font-weight: bold; */
  color: #333;
  color: ${({ theme }) => theme.primaryLight};
  margin-bottom: 2rem;
  text-align: center;
  letter-spacing: -0.5px;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`

const TestimonialsContainer = styled(motion.div)`
  display: flex;
  gap: 2rem;
  width: 100%;
  overflow-x: auto;
  padding: 1rem 2rem;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: unset;
    gap: 1.5rem;
    padding: 0.5rem;
  }
`

const TestimonialCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding: 1.8rem;
  border-radius: 14px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.07);
  width: 380px;
  flex-shrink: 0;
  text-align: left;
  transition: transform 0.3s ease-in-out;
  min-height: 200px;
  word-wrap: break-word;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;
    min-height: unset;
  }
`

const TestimonialText = styled.p`
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  flex-grow: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
`

const Author = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #7d4f35;
  text-align: right;
  margin-top: 1rem;
`

// Testimonials Data
const testimonials = [
  {
    text: "Pour mon entorse de cheville, l’acupuncture a soulagé ma douleur et réduit l’inflammation dès la première séance. Une vraie différence !",
    author: "Patrice T.",
  },
  {
    text: "L’acupuncture a transformé ma vie en apaisant mes allergies. Beaucoup moins de médicaments, plus d’énergie dans mes journées !",
    author: "Martin D.",
  },
  {
    text: "Je n’en reviens pas à quel point l’acupuncture a eu son importance pour m’aider à devenir enceinte et durant ma grossesse ! Wow !",
    author: "Malorie F.",
  },
  {
    text: "Les fleurs de Bach semblent m’avoir aidé à apaiser mon anxiété et jouer un rôle sur mes pensées.",
    author: "Julie B.",
  },
]

const Testimonials = () => {
  return (
    <Section>
      <Title>Témoignages de nos patients</Title>
      <TestimonialsContainer
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ duration: 1.2, ease: "easeOut" }}
      >
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index}>
            <TestimonialText>“{testimonial.text}”</TestimonialText>
            <Author>{testimonial.author}</Author>
          </TestimonialCard>
        ))}
      </TestimonialsContainer>
      <div
        style={{
          fontSize: "1rem",
          color: "#555",
          textAlign: "center",
          marginTop: "2rem",
        }}
      >
        {" "}
        * Les témoignages sont des exemples de résultats obtenus et peuvent
        varier d’une personne à l’autre.
      </div>
    </Section>
  )
}

export default Testimonials
