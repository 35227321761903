import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"
import api from "../../components/utils/api.client"

// Define a type for the user object.
interface User {
  id: string
  email: string
  role: string
}

// Define the AuthState interface.
interface AuthState {
  user: User | null
  loading: boolean
  error: string | null
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  checkAuth: () => Promise<void>
  setError: (error: string) => void
  clearError: () => void
  setLoading: (loading: boolean) => void
  refreshAccessToken: () => Promise<string>
  resetAll: () => void
}

const useAuthStore = create(
  persist<AuthState>(
    (set, get) => ({
      user: null,
      loading: false,
      error: null,

      // Set error message in the store.
      setError: (error: string) => {
        set({ error })
      },

      // Set the loading state.
      setLoading: (loading: boolean) => {
        set({ loading })
      },

      // Clear the error message.
      clearError: () => {
        set({ error: null })
      },

      login: async (email, password) => {
        set({ loading: true })
        try {
          const response = await api.post("/auth/login", { email, password })
          // Assert that response.data is of type User.
          set({ user: response.data as User, error: null })
        } catch (error: any) {
          console.error("Login failed:", error.message)
          set({
            error:
              error.message || "Login failed. Please check your credentials.",
          })
          throw error
        } finally {
          set({ loading: false })
        }
      },

      // Logout method.
      logout: async () => {
        set({ loading: true, error: null })
        try {
          await api.post("/auth/logout", {})
          set({ user: null })
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || "Logout failed"
          console.error("Logout failed:", errorMessage)
          throw new Error(errorMessage)
        } finally {
          set({ loading: false })
        }
      },

      // Check authentication state.
      checkAuth: async () => {
        set({ loading: true, error: null })
        try {
          const response = await api.get("/auth/check")
          set({ user: response.data as User })
        } catch (error) {
          set({ user: null })
          // Optionally set an error here.
        } finally {
          set({ loading: false })
        }
      },

      // Refresh access token.
      refreshAccessToken: async () => {
        try {
          const response = await api.post("/auth/refresh")
          console.log("Token refreshed successfully")
          // Assert that response.data is a string.
          return response.data as string
        } catch (error: any) {
          console.error("Token refresh failed:", error.message)
          throw error
        }
      },

      // Reset all state.
      resetAll: () => {
        set({ user: null, loading: false, error: null })
      },
    }),
    {
      name: "auth-storage", // Persist the state in sessionStorage.
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
)

export default useAuthStore
