import { Helmet } from "react-helmet-async"
import styled from "styled-components"

// --- HERO SECTION ---
const HeroSection = styled.section`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(223, 145, 98, 0.1), rgba(223, 145, 98, 0.2)),
    url("/img/hero-contact.png") center/cover no-repeat;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  margin-top: 15vh;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
    margin-top: 10vh;
  }
`

const HeroContent = styled.div`
  max-width: 900px;

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  }

  p {
    color: white;
    font-size: 1.4rem;
    line-height: 1.8;
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

// --- APPOINTMENT SECTIONS ---
const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  background: ${({ background }) => background || "white"};
  width: 100%;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const Title = styled.h2`
  font-size: 2.8rem;
  color: #3a2c23;
  margin-bottom: 2rem;
  letter-spacing: -0.5px;
  text-align: center;
`

const ClinicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
  max-width: 900px;
`

const ClinicBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
`

const ClinicTitle = styled.h3`
  font-size: 2.2rem;
  color: #3a2c23;
  margin-bottom: 1rem;
  text-transform: uppercase;
`

const Button = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #3a2c23;
  color: white;
  padding: 1rem 1.8rem;
  border-radius: 8px;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;

  &:hover {
    background: #a76d50;
    transform: translateY(-3px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Divider = styled.hr`
  width: 100%;
  max-width: 800px;
  border: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 3rem auto;
`

const ContactSection = styled.div`
  text-align: center;
  margin-top: 3rem;
`

const ContactText = styled.p`
  font-size: 1.4rem;
  color: #3a2c23;
  margin-bottom: 1rem;
  font-weight: 500;
`

const ContactPhone = styled.a`
  font-size: 1.7rem;
  font-weight: bold;
  color: #a76d50;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #814d37;
  }
`

// --- CONTACT PAGE COMPONENT ---
const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Nous joindre | Clinique dö</title>
        <meta
          name="description"
          content="Contactez la Clinique dö pour prendre rendez-vous ou obtenir des informations sur nos services d’acupuncture, ostéopathie, massothérapie et fasciathérapie à Rawdon et Montréal."
        />
        <meta
          name="keywords"
          content="contact clinique dö, contact clinique do, contact clinique acupuncture rawdon, contact clinique ostéopathie rawdon, contact clinique massothérapie rawdon, contact clinique fasciathérapie rawdon, contact clinique acupuncture lanaudière, contact clinique ostéopathie lanaudière, contact clinique massothérapie lanaudière, contact clinique fasciathérapie lanaudière, contact acupuncture rawdon, contact ostéopathie rawdon, contact massothérapie rawdon, contact fasciathérapie rawdon, contact acupuncture lanaudière, contact ostéopathie lanaudière, contact massothérapie"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://cliniquedo.com/nous-joindre" />
      </Helmet>

      {/* HERO */}
      <HeroSection>
        <HeroContent>
          <h1>Réservez votre rendez-vous</h1>
          <p>
            Choisissez votre praticien et planifiez votre séance en ligne ou par
            téléphone.
          </p>
        </HeroContent>
      </HeroSection>

      {/* APPOINTMENT SECTIONS */}
      <Section background="#f9f5f0">
        <Title>Nos cliniques</Title>
        <ClinicsWrapper>
          {/* Clinique Dö RAWDON */}
          <ClinicBlock>
            <ClinicTitle>Clinique Dö RAWDON</ClinicTitle>
            <Button href="https://cliniquedo.webnetrdv.com/?pas_client=3&cap=18">
              Prendre RDV Stefan Pelechaty, Acupuncteur
            </Button>
            <Button href="https://www.gorendezvous.com/fr/acupuncturepourlafamille">
              Prendre RDV Stéphane Turbide, Acupuncteur | Tuina
            </Button>
            <Button href="https://cliniquedo.webnetrdv.com/?cap=29&pas_client=3">
              Prendre RDV Nathalia Levac, Masso | Fasciathérapeute
            </Button>
            <Button href="https://cliniquedo.webnetrdv.com/?cap=26&pas_client=3">
              Prendre RDV Isabelle Ross, Masso
            </Button>
            <Button href="https://cliniquedo.webnetrdv.com/">
              Prendre RDV Renée Dugas, Fleurs de Bach
            </Button>
          </ClinicBlock>

          <Divider />

          {/* Clinique Dö MONTRÉAL */}
          <ClinicBlock>
            <ClinicTitle>Clinique Dö MONTRÉAL</ClinicTitle>
            <Button href="https://cliniquedo.janeapp.com/">
              Prendre RDV Michel Venne, DO et Coaching
            </Button>
            <Button href="https://cliniquedo.webnetrdv.com/?cap=9&pas_client=1">
              Prendre RDV Geneviève Rousseau, DO
            </Button>
          </ClinicBlock>

          <Divider />

          {/* Joindre la centrale de rendez-vous */}
          <ContactSection id="appeler">
            <ClinicTitle>Vous préférez parler à un humain ?</ClinicTitle>
            <ContactText>
              {" "}
              Téléphonez à Renée qui se fera un plaisir de répondre à vos
              questions du lundi au vendredi.
            </ContactText>
            <ContactPhone href="tel:+15147221641">📞 514-722-1641</ContactPhone>
          </ContactSection>
        </ClinicsWrapper>
      </Section>
    </>
  )
}

export default Contact
