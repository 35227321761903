import styled from "styled-components"
import NeedlesIconRef from "@assets/icons/Needles.svg"
import { media } from "@utils/media"

const Section = styled.section`
  /* height: 0vh; */
  width: 100%;
  padding: 5vh 0;
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;

  /* &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    mix-blend-mode: multiply;
    z-index: 1;
  } */

  ${media.md`
    
    height: 42vh;
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-align: center;
  font-size: 24px;
  z-index: 2;

  ${media.md`
  display: grid;
  grid-template-columns: 1fr 4fr;

  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  font-size: 36px;
 `}
`

export const Text = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  margin-top: 1rem;

  ${media.md`
    
    font-size: 2.25rem;
 
  text-align: left;
  `}
`

export const TextHighlight = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.primary};
`

export const NeedlesIcon = styled(NeedlesIconRef)`
  width: 100%;
  max-width: 75px;
  margin: auto;
  height: auto;

  ${media.md`
    max-width: 110px;
  `}
`

export const GeneralSummary = () => {
  return (
    <Section>
      <Content>
        <NeedlesIcon />
        <Text>
          Depuis 2008, les thérapeutes de la Clinique dö mettent en applications{" "}
          <TextHighlight>
            ces pratiques qui ont fait leurs preuves pendant des milliers
            d’années,
          </TextHighlight>{" "}
          aujourd’hui supportées par la science.
        </Text>
      </Content>
    </Section>
  )
}
