import { ReactNode } from "react"

export interface MobileNavItem {
  label: string
  to: string
  icon?: ReactNode
}

export const mobileNavItems: MobileNavItem[] = [
  {
    label: "Accueil",
    to: "/",
  },
  {
    label: "À propos",
    to: "/a-propos",
  },
  {
    label: "Services",
    to: "/services",
  },
  {
    label: "Contact",
    to: "/nous-joindre",
  },
  {
    label: "Accès client",
    to: "/app",
  },
]
