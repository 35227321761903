import { useEffect } from "react"

type EventType = MouseEvent | TouchEvent

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement | null>,
  handler: (event: EventType) => void,
) {
  useEffect(() => {
    const listener = (event: EventType) => {
      const el = ref?.current
      // If clicking outside the element or its descendants
      if (!el || el.contains((event.target as Node) || null)) {
        return
      }
      handler(event)
    }
    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handler])
}
