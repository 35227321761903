// Loader.tsx
import React from "react"
import styled, { keyframes, useTheme, DefaultTheme } from "styled-components"

/* Keyframes for the animations */
const worm1 = keyframes`
  from {
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }
  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }
  to {
    stroke-dashoffset: 0;
  }
`

const worm2 = keyframes`
  from {
    stroke-dashoffset: 358;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -358;
  }
`

/* Styled components for the loader */
const LoaderContainer = styled.main`
  padding: 1.5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SvgLoader = styled.svg`
  width: 16em;
  height: 8em;
`

const Track = styled.g`
  /* Use theme values for stroke, with defaults if not provided */
  stroke: ${({ theme }: { theme: DefaultTheme }) =>
    theme.lightGrey || "#F9F9F7"};
  transition: stroke 0.3s;
  @media (prefers-color-scheme: dark) {
    stroke: ${({ theme }: { theme: DefaultTheme }) =>
      theme.darkGrey || "#4A4A4A"};
  }
`

interface WormProps {
  variant?: "worm1" | "worm2"
}

const Worm = styled.path<WormProps>`
  animation: ${({ variant }) => (variant === "worm2" ? worm2 : worm1)} 2s linear
    infinite;
`

/* Loader component */
const LoaderInfinity: React.FC = () => {
  // Retrieve the theme object from styled-components.
  const theme: DefaultTheme = useTheme()

  // Define gradient colors using theme tokens with defaults.
  const primaryLight = theme.primaryLight || "#3E7B57"
  const highlight = theme.secondary || "#FFE07D"
  const warning = theme.primaryLight || "#E0A431"
  const danger = theme.grey || "#D93741"
  const tertiary = theme.primaryDark || "#969696"
  const info = theme.primary || "#2A8D9C"

  return (
    <LoaderContainer>
      <SvgLoader viewBox="0 0 256 128" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor={primaryLight} />
            <stop offset="33%" stopColor={highlight} />
            <stop offset="67%" stopColor={warning} />
            <stop offset="100%" stopColor={danger} />
          </linearGradient>
          <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
            <stop offset="0%" stopColor={danger} />
            <stop offset="33%" stopColor={tertiary} />
            <stop offset="67%" stopColor={info} />
            <stop offset="100%" stopColor={primaryLight} />
          </linearGradient>
        </defs>
        <g fill="none" strokeLinecap="round" strokeWidth="16">
          <Track>
            <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
            <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
          </Track>
          <g strokeDasharray="180 656">
            <Worm
              variant="worm1"
              stroke="url(#grad1)"
              strokeDashoffset="0"
              d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
            />
            <Worm
              variant="worm2"
              stroke="url(#grad2)"
              strokeDashoffset="358"
              d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
            />
          </g>
        </g>
      </SvgLoader>
    </LoaderContainer>
  )
}

export default LoaderInfinity
