import { useState, useEffect } from "react"

//To detect scroll direction and control show/hide header
export function useScrollDirection() {
  const [isVisible, setIsVisible] = useState(true)
  const [lastScroll, setLastScroll] = useState(0)
  const HERO_THRESHOLD = 300 // to adjust to hero's height
  const [isSolidBackground, setIsSolidBackground] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      const currentScroll = window.scrollY
      // Show/hide logic
      if (currentScroll > lastScroll) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
      setLastScroll(currentScroll <= 0 ? 0 : currentScroll)

      // Toggle background
      if (currentScroll > HERO_THRESHOLD) {
        setIsSolidBackground(true)
      } else {
        setIsSolidBackground(false)
      }
    }
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [lastScroll])

  return { isVisible, isSolidBackground }
}
