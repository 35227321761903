import React from "react"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"

// --- HERO SECTION ---
const HeroSection = styled.section`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(223, 145, 98, 0.2), rgba(223, 145, 98, 0.4)),
    url("/img/massotherapie3.jpg") center/cover no-repeat;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  margin-top: 15vh;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
    margin-top: 10vh;
  }
`

const HeroContent = styled.div`
  max-width: 800px;

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  }

  p {
    color: white;
    font-size: 1.5rem;
    line-height: 1.8;
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

// --- CONTENT SECTIONS ---
const Section = styled.section`
  width: 100%;
  padding: 6rem 2rem;
  background: ${({ background }) => background || "white"};

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.7;
  }
`

const BulletList = styled.ul`
  margin-top: 1rem;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #d19166;
  }

  @media (max-width: 768px) {
    margin: 0 1rem;
    margin-top: 2.5rem;
    text-align: left;
    li {
      font-size: 1rem;
    }
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  height: 350px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    height: 280px;
  }
`

const CTAButton = styled.a`
  display: inline-block;
  background: #d19166;
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  margin-top: 2rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #b57b55;
    transform: translateY(-3px);
  }
`

// --- FASCIATHÉRAPIE & MASSOTHÉRAPIE PAGE COMPONENT ---
const MassageTherapy = () => {
  return (
    <>
      <Helmet>
        <title>
          Clinique Dö | Fasciathérapie & Massothérapie à Rawdon – Libérez vos
          tensions, révélez votre équilibre
        </title>
        <meta
          name="description"
          content="Découvrez les bienfaits de la fasciathérapie et de la massothérapie à la Clinique Dö à Rawdon. Libérez vos tensions et retrouvez votre équilibre avec nos soins thérapeutiques."
        />
        <meta
          name="keywords"
          content="
          fasciathérapie rawdon, massothérapie rawdon, fasciathérapie lanaudière, massothérapie lanaudière, soins thérapeutiques rawdon, bien-être rawdon, détente rawdon, soulagement douleur rawdon, récupération musculaire rawdon
        "
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://cliniquedo.com/services/fasciatherapie-massotherapie"
        />
      </Helmet>
      {/* HERO */}
      <HeroSection>
        <HeroContent>
          <h1>
            Fasciathérapie & Massothérapie : libérez vos tensions, révélez votre
            équilibre!
          </h1>
          <p>Détente | Soulagement de la douleur | Récupération musculaire</p>
        </HeroContent>
      </HeroSection>

      {/* CONTENT SECTIONS */}
      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Pourquoi choisir la massothérapie ?</h2>
              <p>
                La massothérapie est l’une des techniques les plus efficaces
                pour relâcher les tensions, améliorer la circulation sanguine et
                accélérer la récupération musculaire.
                <br />
                <br />
                Que vous souffriez de douleurs chroniques, de tensions liées au
                stress ou que vous recherchiez un moment de bien-être profond,
                nos massothérapeutes sont là pour vous.
              </p>
            </TextContent>
            <ImageWrapper>
              <img src="/img/service-massotherapie.jpg" alt="Massothérapie" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Fasciathérapie vs Massothérapie</h2>
              <p>
                <strong>Fasciathérapie :</strong> Thérapie manuelle douce qui
                agit sur les fascias pour améliorer la mobilité et stimuler les
                capacités d’autorégulation du corps.
                <br />
                <br />
                <strong>Massothérapie :</strong> Approche plus globale visant à
                détendre les muscles et favoriser la relaxation avec des
                techniques variées.
              </p>
              <br />
              <p>
                En résumé, la fasciathérapie agit en profondeur sur la structure
                du corps et ses tensions subtiles, tandis que la massothérapie
                vise principalement à détendre les muscles et favoriser la
                relaxation.
              </p>
            </TextContent>
            <ImageWrapper>
              <img src="/img/massotherapie5.jpg" alt="Fasciathérapie" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Nos massages thérapeutiques</h2>
              <BulletList>
                <li>
                  <span>💆</span> <strong>Massothérapie thérapeutique</strong>
                </li>
                <li>✔ Soulagement des douleurs musculaires et articulaires</li>
                <li>✔ Libération des tensions accumulées</li>
                <li>✔ Amélioration de la posture et de la souplesse</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>💤</span> <strong>Massothérapie relaxante</strong>
                </li>
                <li>✔ Gestion du stress et de l’anxiété</li>
                <li>✔ Réduction des tensions nerveuses</li>
                <li>✔ Amélioration de la qualité du sommeil</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>🤰</span> <strong>Fasciathérapie</strong>
                </li>
                <li>✔ Réduction des douleurs et tensions générales</li>
                <li>✔ Amélioration de la circulation sanguine</li>
                <li>
                  ✔ Favorise le bien-être global et libère le corps, l’esprit
                  et le mouvement
                </li>
              </BulletList>
            </TextContent>
            <ImageWrapper>
              <img src="/img/massotherapie4.jpg" alt="Massage thérapeutique" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <TextContent>
            <h2>Pourquoi choisir notre clinique ?</h2>
            <p>✔ Thérapeutes expérimentés et certifiés</p>
            <p>✔ Massages adaptés à votre état de santé</p>
            <p>✔ Une approche professionnelle et humaine</p>
            <CTAButton href="/nous-joindre">
              Prenez rendez-vous pour une séance →
            </CTAButton>
          </TextContent>
        </ContentWrapper>
      </Section>
    </>
  )
}

export default MassageTherapy
