import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import useAuthStore from "../../features/auth/authStore.client"

// Base URL from environment variables, fallback to localhost:5000 for dev
const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? `${import.meta.env.VITE_APP_URL}/api`
    : "/api"

// Error structure for consistent error handling
interface ApiError {
  message: string
  statusCode?: number
  details?: Record<string, unknown>
}

// Extend AxiosResponse for API typing
interface ApiResponse<T = unknown> extends AxiosResponse {
  data: T
}

// Queue for failed requests during token refresh
let isRefreshing = false
const failedQueue: Array<{
  resolve: (value?: unknown) => void
  reject: (reason?: unknown) => void
}> = []

function addToFailedQueue(
  resolve: (value?: unknown) => void,
  reject: (reason?: unknown) => void,
) {
  failedQueue.push({ resolve, reject })
}

function processQueue(error: unknown = null) {
  if (error) {
    failedQueue.forEach(({ reject }) => reject(error))
  } else {
    failedQueue.forEach(({ resolve }) => resolve())
  }
  failedQueue.length = 0 // Clear the queue
}

// Create an Axios instance
function createAxiosInstance(): AxiosInstance {
  const instance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true, // Allow cookies for backend-only auth
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
    },
  })

  setupInterceptors(instance)
  return instance
}

function setupInterceptors(instance: AxiosInstance) {
  instance.interceptors.request.use(
    (config) => {
      // You could add auth headers here if needed

      return config
    },
    (error) => Promise.reject(error),
  )

  instance.interceptors.response.use(
    (response) => response,
    async (error: unknown) => {
      const { refreshAccessToken, logout, setError } = useAuthStore.getState()
      const typedError = error as {
        config?: AxiosRequestConfig
        response?: any
      }
      const originalRequest = typedError.config

      // If there is no response or no status, it's a network error or similar
      if (!typedError.response) {
        return Promise.reject(error)
      }

      // If the error status is 401 (Unauthorized)
      if (typedError.response.status === 401) {
        if (
          originalRequest?.url &&
          originalRequest.url.includes("auth/check")
        ) {
          // Do not attempt refresh for auth check
          return Promise.reject(error)
        }
        /**
         * OPTIONAL: If we have certain endpoints that should never attempt a refresh,
         * we could do something like:
         *
         * if (originalRequest.url.includes('/some-endpoint')) {
         *   return Promise.reject(error);
         * }
         *
         * Otherwise, we'll attempt refresh for all 401s.
         */

        // If already retrying a token refresh, queue this request
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            addToFailedQueue(resolve, reject)
          }).then(() => instance(originalRequest))
        }

        isRefreshing = true
        try {
          // (Removed console.log to fix the no-console warning)
          await refreshAccessToken() // Attempt to get fresh tokens
          // Process any queued requests that came in during the refresh
          processQueue()
          // Retry the original request now that we (hopefully) have fresh tokens
          return instance(originalRequest)
        } catch (refreshError) {
          // (Removed console.error to fix the no-console warning)
          processQueue(refreshError)
          logout() // This will clear user state
          return Promise.reject(refreshError)
        } finally {
          isRefreshing = false
        }
      }

      // For non-401 errors, just format & reject
      const formattedError = formatError(error)
      setError(formattedError.message)
      return Promise.reject(formattedError)
    },
  )
}

function formatError(error: unknown): ApiError {
  const typedError = error as {
    response?: { data?: { message?: string }; status?: number }
    message?: string
  }
  return {
    message:
      typedError.response?.data?.message ||
      typedError.message ||
      "An unexpected error occurred",
    statusCode: typedError.response?.status,
    details: typedError.response?.data || {},
  }
}

// Create the axios instance
const apiInstance = createAxiosInstance()

// Expose typed wrappers for get/post/put/delete
export const api = {
  get: async <T,>(
    url: string,
    params?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> => {
    const query = params
      ? `?${new URLSearchParams(params as Record<string, string>).toString()}`
      : ""
    return apiInstance.get<T>(`${url}${query}`, config)
  },

  post: async <T,>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> => {
    try {
      return await apiInstance.post<T>(url, data, config)
    } catch (error: any) {
      console.error(`Error in POST request to ${url}:`, error)
      throw formatError(error)
    }
  },

  put: async <T,>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> => {
    return apiInstance.put<T>(url, data, config)
  },

  delete: async <T,>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> => {
    return apiInstance.delete<T>(url, config)
  },
}

export default api
