import styled from "styled-components"
import { Helmet } from "react-helmet-async"

// --- HERO SECTION ---
const HeroSection = styled.section`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(223, 145, 98, 0.2), rgba(223, 145, 98, 0.4)),
    url("/img/hero-osteopathy.jpg") center/cover no-repeat;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  margin-top: 15vh;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
    margin-top: 10vh;
  }
`

const HeroContent = styled.div`
  max-width: 800px;

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  }

  p {
    color: white;
    font-size: 1.5rem;
    line-height: 1.8;
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

// --- CONTENT SECTIONS ---
interface SectionProps {
  background?: string
}

const Section = styled.section<SectionProps>`
  width: 100%;
  padding: 6rem 2rem;
  background: ${({ background }) => background || "white"};

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.7;
  }
`

const Quote = styled.blockquote`
  font-size: 1.5rem;
  font-style: italic;
  color: #333;
  margin-top: 2rem;
  padding-left: 1rem;
  border-left: 4px solid #d19166;
`

const BulletList = styled.ul`
  margin-top: 1rem;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #d19166;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  height: 350px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    height: 280px;
  }
`

const CTAButton = styled.a`
  display: inline-block;
  background: #d19166;
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  margin-top: 2rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #b57b55;
    transform: translateY(-3px);
  }
`

// --- L’OSTÉOPATHIE PAGE COMPONENT ---
const Osteopathy = () => {
  return (
    <>
      <Helmet>
        <title>
          Clinique Dö | Ostéopathie à Rawdon – Soins thérapeutiques pour
          douleurs et bien-être
        </title>
        <meta
          name="description"
          content="Clinique Dö à Rawdon offre des soins ostéopathiques pour douleurs et bien-être. Prenez rendez-vous dès maintenant!"
        />
        <meta
          name="keywords"
          content="clinique dö, clinique do, ostéopathie rawdon, ostéopathie lanaudière, soins ostéopathiques, douleurs, bien-être"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://cliniquedo.com/services/osteopathie"
        />
      </Helmet>
      {/* HERO */}
      <HeroSection>
        <HeroContent>
          <h1>L’ostéopathie : rétablir l’harmonie du corps</h1>
          <p>Douleurs articulaires | Mobilité | Rééquilibrage global</p>
        </HeroContent>
      </HeroSection>

      {/* CONTENT SECTIONS */}
      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Pourquoi choisir l’ostéopathie ?</h2>
              <p>
                L’ostéopathie est une approche thérapeutique manuelle qui agit
                sur les dysfonctions musculo-squelettiques, nerveuses,
                digestives et circulatoires. En identifiant la cause
                sous-jacente de vos douleurs, nos ostéopathes vous aident à
                retrouver une mobilité optimale et à réduire les tensions du
                corps.
              </p>
              <Quote>
                "L'ostéopathie ne soigne pas les maladies, elle soigne les
                individus." – A.T. Still, fondateur de l’ostéopathie
              </Quote>
            </TextContent>
            <ImageWrapper>
              <img src="/img/osteo1.png" alt="Ostéopathie" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Comment fonctionne l’ostéopathie ?</h2>
              <BulletList>
                <li>
                  <span>🦵</span>{" "}
                  <strong>Troubles musculo-squelettiques</strong>
                </li>
                <li>✔ Douleurs au dos, aux épaules et au cou</li>
                <li>✔ Blocages articulaires, tensions musculaires</li>
                <li>✔ Sciatique, lombalgie, hernie discale</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>🫀</span>{" "}
                  <strong>Troubles circulatoires et digestifs</strong>
                </li>
                <li>✔ Mauvaise circulation sanguine, jambes lourdes</li>
                <li>✔ Ballonnements, troubles du transit</li>
                <li>✔ Reflux gastriques et inconforts intestinaux</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>🧘</span>{" "}
                  <strong>Troubles du stress et du sommeil</strong>
                </li>
                <li>✔ Fatigue chronique, migraines</li>
                <li>✔ Problèmes de concentration, anxiété</li>
              </BulletList>
            </TextContent>
            <ImageWrapper>
              <img src="/img/osteo2.jpg" alt="Traitement ostéopathique" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="#f9f5f0">
        <ContentWrapper>
          <TextContent>
            <h2>Pourquoi choisir notre ostéopathie ?</h2>
            <p>✔ Des ostéopathes qualifiés et expérimentés</p>
            <p>
              ✔ Approche globale pour traiter la cause, pas juste les symptômes
            </p>
            <p>✔ Méthodes douces et non invasives</p>
            <CTAButton href="/nous-joindre">
              Prenez rendez-vous pour une séance →
            </CTAButton>
          </TextContent>
        </ContentWrapper>
      </Section>
    </>
  )
}

export default Osteopathy
