import React, { useState, useRef, useEffect } from "react"
import { useLocation } from "react-router-dom"
import {
  HeaderLayout,
  TopBar,
  LogoWrapper,
  PrimaryNav,
  SecondaryNavBar,
  NavItemLink,
  PhoneNumber,
  CTAButton,
  MobileNavContainer,
  MobileNav,
  MobileMenuIcon,
  MobileCloseIcon,
  // Overlay,
  DesktopOnlyWrapper,
} from "./Style"

import { desktopNavItems, secondaryNavItems } from "./data/desktopNavItems"
import { mobileNavItems } from "./data/mobileNavItems"

// Hooks
import { useScrollDirection } from "./hooks/useScrollDirection"
import { useSmoothScrolling } from "./hooks/useSmoothScrolling"
import { useOnClickOutside } from "../../../../components/hooks/useOnClickOutside"

// Components
import { DropdownNavItem } from "./DropdownNavItem"

// Assets
import Logo from "../../../../app/assets/Logo.svg"

export const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const mobileNavRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const { isVisible, isSolidBackground } = useScrollDirection()
  const { handleSmoothScroll } = useSmoothScrolling()
  const isClientAccess =
    location.pathname === "/acces-client" || location.pathname === "/app"

  // Clicking outside mobile nav closes it
  useOnClickOutside(mobileNavRef, () => {
    if (menuOpen) setMenuOpen(false)
  })

  // Lock body scroll when menu is open
  useEffect(() => {
    document.body.style.overflow = menuOpen ? "hidden" : "auto"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [menuOpen])

  return (
    <>
      <HeaderLayout
        isClientAccess={isClientAccess}
        isVisible={isVisible}
        isSolidBackground={isSolidBackground}
      >
        {/* 
          TopBar – Contains the logo, primary nav, phone #, CTA (Desktop),
          and hamburger menu. 
          On mobile, phone # and CTA are hidden (we show them inside the mobile menu instead).
        */}
        <TopBar>
          {/* <Overlay menuOpen={menuOpen} /> */}

          <LogoWrapper to="/">
            <Logo />
          </LogoWrapper>

          {/* Desktop Nav */}
          <PrimaryNav className="desktopOnly">
            {desktopNavItems.map((item, index) =>
              item.childItems ? (
                <DropdownNavItem key={index} item={item} />
              ) : (
                <NavItemLink
                  key={index}
                  to={item.to}
                  onClick={(e) => handleSmoothScroll(e, item.to)}
                >
                  {item.label}
                </NavItemLink>
              ),
            )}
          </PrimaryNav>

          {/* Desktop phone + CTA */}
          <DesktopOnlyWrapper>
            <PhoneNumber
              href="tel:514-722-1641"
              onClick={() => {
                window.gtag("event", "click", {
                  event_category: "phone",
                  event_label: "header",
                })
              }}
            >
              514-722-1641
            </PhoneNumber>
            <CTAButton to="/nous-joindre">Prendre rendez-vous</CTAButton>
          </DesktopOnlyWrapper>

          {/* Hamburger for mobile */}
          <MobileMenuIcon
            aria-label="Toggle Mobile Menu"
            onClick={() => setMenuOpen((prev) => !prev)}
            className="mobileOnly"
          >
            ☰
          </MobileMenuIcon>
        </TopBar>

        {/* 
          SecondaryNavBar – Always visible on desktop. Hidden on mobile. 
          You can see it’s forced display: none by default, display: flex in media.md
        */}
        <SecondaryNavBar>
          {secondaryNavItems.slice(0, 4).map((item, index) =>
            item.childItems ? (
              <DropdownNavItem key={index} item={item} />
            ) : (
              <NavItemLink
                key={index}
                to={item.to}
                onClick={(e) => handleSmoothScroll(e, item.to)}
              >
                {item.label}
              </NavItemLink>
            ),
          )}

          <div style={{ flex: 1 }}></div>

          {secondaryNavItems.slice(4).map((item, index) =>
            item.childItems ? (
              <DropdownNavItem key={index} item={item} />
            ) : (
              <NavItemLink
                key={index}
                to={item.to}
                onClick={(e) => handleSmoothScroll(e, item.to)}
              >
                {item.label}
              </NavItemLink>
            ),
          )}
        </SecondaryNavBar>

        {/* 
          Mobile Nav Container (full screen) – only visible on mobile. 
          Contains phone # and CTA, centered vertically/horizontally. 
        */}
        <MobileNavContainer
          ref={mobileNavRef}
          menuOpen={menuOpen}
          variant="fullScreen"
          aria-hidden={!menuOpen}
        >
          <MobileCloseIcon
            aria-label="Close Mobile Menu"
            onClick={() => setMenuOpen(false)}
          >
            ×
          </MobileCloseIcon>

          <MobileNav>
            {mobileNavItems.map((item, index) => (
              <NavItemLink
                key={index}
                to={item.to}
                onClick={(e) =>
                  handleSmoothScroll(e, item.to, () => setMenuOpen(false))
                }
              >
                {item.label}
              </NavItemLink>
            ))}

            {/* Mobile phone + CTA (centered with the links) */}
            <PhoneNumber
              href="tel:514-722-1641"
              onClick={() => {
                window.gtag("event", "click", {
                  event_category: "phone",
                  event_label: "header",
                })
              }}
            >
              514-722-1641
            </PhoneNumber>
            <CTAButton to="/nous-joindre" onClick={() => setMenuOpen(false)}>
              Prendre rendez-vous
            </CTAButton>
          </MobileNav>
        </MobileNavContainer>
      </HeaderLayout>
    </>
  )
}
