import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
*, *::before, *::after{
  box-sizing:border-box;
  -webkit-box-sizing: border-box;
}


:root {

  }
  
  body {
    margin: auto;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Almarai', sans-serif;
    /* font-weight:100; */

    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.primaryText};
    font-family: ${({ theme }) => theme.fontFamily};
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  // Typography: Headers

  h1,h2,h3,h4,h5,h6{
  font-family: 'Almarai', sans-serif;
  font-weight:400;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 1.2;
  color: ${({ theme }) => theme.primaryText};
}

p {
  font-family: 'Almarai';
  font-weight:100;
  line-height: 1.5;
  color: ${({ theme }) => theme.primaryText};

  font-family: "Almarai", serif;
  font-optical-sizing: auto;

  font-style: normal;
  font-variation-settings:
    "GRAD" 0;
}

a, span, li, input, textarea, button {

  
}

form{
  
*{
    &:focus{
    outline:2.5px solid ${({ theme }) => theme.primary};
  }

}
}

textarea{
  font-family: 'Almarai';
  font-weight:normal;
}

  // Animations

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fadeIn 1s ease-in;
  }
`

export default GlobalStyle
