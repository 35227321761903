import React from "react"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"

// --- HERO SECTION ---
const HeroSection = styled.section`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(223, 145, 98, 0.2), rgba(223, 145, 98, 0.4)),
    url("/img/fleurs-de-bach1.jpg") center/cover no-repeat;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  margin-top: 15vh;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
    margin-top: 10vh;
  }
`

const HeroContent = styled.div`
  max-width: 800px;

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  }

  p {
    color: white;
    font-size: 1.5rem;
    line-height: 1.8;
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

// --- CONTENT SECTIONS ---
const Section = styled.section`
  width: 100%;
  padding: 6rem 2rem;
  background: ${({ background }) => background || "white"};

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.7;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

export const Quote = styled.blockquote`
  font-size: 1.5rem;
  font-style: italic;
  color: #333;
  margin-top: 2rem;
  padding-left: 1rem;
  border-left: 4px solid #d19166;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    margin: 0.3rem;
  }
`

const BulletList = styled.ul`
  margin-top: 3rem;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #333;
    margin: 1.2rem 0;
  }

  span {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #d19166;
  }

  @media (max-width: 768px) {
    margin: 0 1rem;
    margin-top: 2.5rem;
    text-align: left;
    li {
      font-size: 1rem;
    }
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  height: 350px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    height: 280px;
  }
`

const CTAButton = styled.a`
  display: inline-block;
  background: #d19166;
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  margin-top: 2rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #b57b55;
    transform: translateY(-3px);
  }
`

// --- FLEURS DE BACH PAGE COMPONENT ---
const BachFlowers = () => {
  return (
    <>
      <Helmet>
        <title>
          Clinique Dö | Fleurs de Bach – Rétablir l’harmonie du corps et de
          l'esprit
        </title>
        <meta
          name="description"
          content="Découvrez les bienfaits des Fleurs de Bach pour rétablir l’harmonie du corps et de l’esprit. Gestion du stress, anxiété, et rééquilibrage émotionnel."
        />
        <meta
          name="keywords"
          content="
        fleurs de bach, rééquilibrage émotionnel, gestion du stress, anxiété, bien-être, harmonie du corps, thérapie naturelle, Dr Edward Bach
      "
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://cliniquedo.com/services/fleurs-de-bach"
        />
      </Helmet>

      {/* HERO */}
      <HeroSection>
        <HeroContent>
          <h1>Fleurs de Bach : rétablir l’harmonie du corps et de l'esprit</h1>
          <p>
            Anxiété | Blocage psycho-émotif précis | Rééquilibrage émotionnel
          </p>
        </HeroContent>
      </HeroSection>

      {/* CONTENT SECTIONS */}
      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Qu'est-ce que les Fleurs de Bach ?</h2>
              <p>
                Les fleurs de Bach sont un système de remèdes naturels développé
                dans les années 1930 par le Dr Edward Bach. Ce système comprend{" "}
                <b>38 élixirs</b> fabriqués à partir de fleurs spécifiques,
                chacun ciblant un état émotionnel particulier. Utilisées pour{" "}
                <b>rééquilibrer les émotions</b>, elles soutiennent la gestion
                du stress, de l'anxiété, de la tristesse ou des peurs.
              </p>
              <Quote>
                "Les fleurs de Bach agissent sur les états émotionnels et
                permettent à l’individu de retrouver l’équilibre et la santé." –
                Dr Edward Bach
              </Quote>
            </TextContent>
            <ImageWrapper>
              <img src="/img/fleurs-de-bach2.jpg" alt="Fleurs de Bach" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Comment fonctionnent les fleurs de Bach ?</h2>
              <BulletList>
                <li>
                  <span>🌿</span>
                  <b>Réduire le stress et l’anxiété</b>
                </li>
                <li>✔ Apaiser les angoisses et le stress quotidien.</li>
                <li>
                  <span>💭</span>
                  <b>Surmonter les peurs et phobies</b>
                </li>
                <li>
                  ✔ Adresser des peurs inexpliquées, connues ou paniques.
                </li>
                <li>
                  <span>💡</span>
                  <b>Renforcer la confiance en soi</b>
                </li>
                <li>✔ Surmonter le doute et renforcer l'estime de soi.</li>
                <li>
                  <span>🌙</span>
                  <b>Troubles du sommeil</b>
                </li>
                <li>
                  ✔ Un mental trop actif empêchant un sommeil réparateur.
                </li>
                <li>
                  <span>💖</span>
                  <b>Émotions & relations</b>
                </li>
                <li>
                  ✔ Lutter contre la tristesse, la jalousie et la colère.
                </li>
              </BulletList>
            </TextContent>
            <ImageWrapper>
              <img
                src="/img/fleurs-de-bach3.jpg"
                alt="Traitement fleurs de Bach"
              />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="#f9f5f0">
        <ContentWrapper>
          <TextContent>
            <h2>Pourquoi consulter en Fleurs de Bach ?</h2>
            <p>✔ Approche axée sur l’humain</p>
            <p>
              ✔ Structurer son discours intérieur et cibler l’émotion source
            </p>
            <p>✔ Se donner un espace pour soi et mieux se comprendre</p>
            <CTAButton href="/nous-joindre">
              Prenez rendez-vous pour une séance →
            </CTAButton>
          </TextContent>
        </ContentWrapper>
      </Section>
    </>
  )
}

export default BachFlowers
