export const themeSettings = {
  modes: {
    light: {
      mode: "light",

      // Core Brand Colors
      primary: "#DF9162", //  ---------------------- THEME COLOR PALETTE 1
      primaryLight: "#F9D3BA", // Lighter shade of primary
      primaryDark: "#A66438", // Darker shade of primary

      // Accent & Secondary Colors
      secondary: "#4A4A4A", // Neutral dark grey ---------------------- THEME COLOR PALETTE 2
      tertiary: "#969696", // Muted grey accent
      highlight: "#FFE07D", // Soft yellow for attention

      // Status Colors (Modern & Professional)
      success: "#31A66D", // Sleek green
      warning: "#E0A431", // Warm amber
      danger: "#D93741", // Modern red
      info: "#2A8D9C", // Muted teal

      // Neutral Colors
      white: "#FFFFFF",
      black: "#000000",
      grey: "#969696", // ---------------------- THEME COLOR PALETTE 4
      lightGrey: "#F9F9F7", // Modern off-white ---------------------- THEME COLOR PALETTE 3
      darkGrey: "#4A4A4A",

      // Text Colors
      primaryText: "#525252", //
      secondaryText: "#4A4A4A", // Neutral grey for contrast
      link: "#247BA0", // Muted blue-green for links

      // Background Colors
      backgroundPrimary: "#F9F9F7", // Soft off-white
      backgroundSecondary: "#EDEDEB", // Subtle beige-grey
      backgroundTertiary: "#FFFFFF", // Clean white
    },

    dark: {
      mode: "dark",

      // Core Brand Colors (Dark Mode Variants)
      primary: "#1E4A32", // Darkened deep green
      primaryLight: "#3E7B57", // Keep the same contrast
      primaryDark: "#142F22", // Even darker green

      // Accent & Secondary Colors (Dark Mode)
      secondary: "#969696", // Soft grey (keeps contrast)
      tertiary: "#4A4A4A", // Dark grey for elements
      highlight: "#FFC861", // Warmer yellow for contrast

      // Status Colors (Dark Mode Variants)
      success: "#2D8A5F", // Softer green
      warning: "#B97D29", // Rich amber
      danger: "#C42D37", // Deep, modern red
      info: "#1D6F7D", // Dark teal

      // Neutral Colors
      white: "#FFFFFF",
      black: "#000000",
      grey: "#969696",
      lightGrey: "#3A3A3A", // Darker but still soft
      darkGrey: "#171717", // Rich dark background

      // Text Colors
      primaryText: "#F9F9F7", // Off-white text for contrast
      secondaryText: "#969696", // Muted grey text
      link: "#78DDE2", // Cool blue-green for links

      // Background Colors (Dark Mode)
      backgroundPrimary: "#0F1012", // Deep black-blue
      backgroundSecondary: "#171717", // Darker grey
      backgroundTertiary: "#282A44", // Slightly bluish-grey
    },
  },

  // Shared Typography Tokens
  typography: {
    fontFamily: "Nunito Sans, Arial, sans-serif",
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "16px",
      lg: "18px",
      xl: "24px",
      xxl: "32px",
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      bold: 700,
    },
  },

  // Spacing Scale
  spacing: {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
    xxl: "48px",
  },

  // Breakpoints for Responsive Design
  breakpoints: {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    tablet: "768px",
    desktop: "992px",
    widescreen: "1200px",
    extraWidescreen: "1400px",
  },

  // Component-Level Tokens
  components: {
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "12px",
      round: "50%",
    },
    boxShadow: {
      small: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      medium: "0px 4px 8px rgba(0, 0, 0, 0.15)",
      large: "0px 6px 12px rgba(0, 0, 0, 0.2)",
    },
    transitionSpeed: "400ms",
  },

  // Z-Index Layers for Component Stacking
  zIndex: {
    base: 0,
    dropdown: 100,
    overlay: 1000,
    modal: 1050,
    popover: 1100,
    tooltip: 1200,
  },
}

export default themeSettings

/**
 * Helper function to dynamically merge the mode colors
 * with the shared tokens (typography, spacing, etc.)
 */
export const buildTheme = (mode: "light" | "dark") => {
  const { modes, ...sharedTokens } = themeSettings
  const modeColors = modes[mode]

  return {
    ...modeColors,
    ...sharedTokens,
  }
}
