import styled from "styled-components"
import { motion } from "framer-motion"

const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  background: url("/img/forms-bg.jpg") center/cover no-repeat;
  color: white;
  text-align: center;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  max-width: 800px;
  width: 100%;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
`

const FormList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const FormItem = styled(motion.li)`
  background: rgba(255, 255, 255, 0.15);
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 1.2rem;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
`

const forms = [
  "Demande de devis",
  "Formulaire d'inscription",
  "Demande d'assistance",
  "Réclamation",
  "Autres formulaires",
]

const Resources = () => {
  return (
    <Layout>
      <Overlay />
      <Content>
        <Title>Formulaires</Title>
        <FormList>
          {forms.map((form, index) => (
            <FormItem
              key={index}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {form}
            </FormItem>
          ))}
        </FormList>
      </Content>
    </Layout>
  )
}

export default Resources
