import { css, DefaultTheme } from "styled-components"

/**
 * A map of reusable media query helpers
 * that produce css blocks for each breakpoint.
 *
 * Usage example:
 *   ${media.sm`
 *      background: red;
 *      color: white;
 *   `}
 */

const mediaQuery =
  (breakpointKey: keyof DefaultTheme["breakpoints"]) =>
  (styles: TemplateStringsArray) => css`
    @media (min-width: ${({ theme }) => theme.breakpoints[breakpointKey]}) {
      ${styles}
    }
  `

export const media = {
  // For tablets and up – assume mobile is the default.
  md: mediaQuery("md"),

  // For desktops and up.
  lg: mediaQuery("lg"),

  // Optionally, for extra-large screens.
  xl: mediaQuery("xl"),
}
