import styled, { css } from "styled-components"
import { Link, NavLink } from "react-router-dom"
import { media } from "@utils/media"

/* -------------------------------------------------------------------------
   Interfaces
------------------------------------------------------------------------- */
interface HeaderLayoutProps {
  isClientAccess: boolean
  isVisible: boolean
  isSolidBackground: boolean
}

interface MobileNavContainerProps {
  menuOpen: boolean
  variant?: "fullScreen" | "slideOut"
}

interface OverlayProps {
  menuOpen: boolean
}

/* -------------------------------------------------------------------------
   Utility Wrappers for conditional rendering
   (desktopOnly, mobileOnly, etc.)
------------------------------------------------------------------------- */
export const DesktopOnlyWrapper = styled.div`
  display: none;
  ${media.md`
    display: flex;
    align-items: center;
    gap: 1rem;
  `}
`

export const MobileOnlyWrapper = styled.div`
  display: block;
  ${media.md`
    display: none;
  `}
`

/* -------------------------------------------------------------------------
   HeaderLayout – The container for the entire header (both rows).
   Fixed at the top with slide-down / slide-up animation.
------------------------------------------------------------------------- */
export const HeaderLayout = styled.header<HeaderLayoutProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  transition:
    transform 0.3s ease,
    background-color 0.3s ease;
  transform: translateY(${(props) => (props.isVisible ? "0" : "-100%")});

  background: url("/img/granit-texture.jpg") no-repeat center center;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Smooth gradient from primary to primaryLight with controlled opacity */
    background: linear-gradient(
      20deg,
      ${({ theme }) => theme.primaryLight},
      ${({ theme }) => theme.primary}
    );
    opacity: 0.5;
    z-index: 0;
  }

  /* Ensure all header content sits above the overlay */
  & > * {
    position: relative;
    z-index: 1;
  }

  ${(props) =>
    props.isClientAccess &&
    css`
      background-color: #333;
      color: #fff;
    `}
`

/* -------------------------------------------------------------------------
   TopBar – The upper row (logo, nav, phone, CTA, hamburger).
   We want less padding on mobile, so we do that with a media query.
------------------------------------------------------------------------- */
export const TopBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  /* Default (mobile) padding smaller. */
  padding: 0.6rem 1.5rem;
  min-height: 60px;
  z-index: 2;

  /* On desktop, increase padding. */
  ${media.md`
    padding: 0 4rem;
    min-height: 13vh;
  `}
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: inherit;

  @media (max-width: 768px) {
    margin-left: 0.5rem;
  }
`

export const NavItemLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  padding: 0.5rem 1rem;
  position: relative;
  display: flex;
  font-family: "Almarai", sans-serif;

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }
`

export const PhoneNumber = styled.a`
  color: ${({ theme }) => theme.white};
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Almarai", sans-serif;
  /* margin-top: 2rem;
  margin-bottom: 1rem; */

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    color: ${({ theme }) => theme.primary};
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`

export const CTAButton = styled(NavLink)`
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.white};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Almarai", sans-serif;
  font-weight: 600;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    transition: background-color 0.2s;
  }
`

/* -------------------------------------------------------------------------
   PrimaryNav – Desktop primary navigation
------------------------------------------------------------------------- */
export const PrimaryNav = styled.nav`
  display: none;

  ${media.md`
    display: flex;
    gap: 1rem;
  `}
`

/* -------------------------------------------------------------------------
   SecondaryNavBar – The lower row on desktop
------------------------------------------------------------------------- */
export const SecondaryNavBar = styled.nav`
  display: none;
  width: 100%;
  background-color: #fff;
  padding: 0 4rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;

  a {
    color: ${({ theme }) => theme.primary};
    font-family: "Almarai", sans-serif;
  }

  ${media.md`
    display: flex;
  `}
`

/* -------------------------------------------------------------------------
   Mobile Navigation – Full-screen panel
------------------------------------------------------------------------- */
export const MobileNavContainer = styled.div<MobileNavContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1100;
  width: 100%;
  min-height: 100vh;
  background: #fff;

  /* Slide in from the top instead of from the right */
  transform: translateY(${(props) => (props.menuOpen ? "0" : "-120%")});
  transition: transform 0.3s ease;

  /* If you want the user to scroll inside the menu if content is too large */
  /* overflow-y: auto; */
  -webkit-overflow-scrolling: touch;

  /* Hide on desktop. */
  ${media.md`
    display: none;
  `}
`

export const MobileNav = styled.nav`
  /* We center everything in the full screen. */
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${NavItemLink} {
    margin: 1rem 0;
    font-size: 1.2rem;
    color: #333 !important;
  }
`

/* -------------------------------------------------------------------------
   Overlay – a semi-transparent backdrop behind the mobile nav 
   so users can click outside to close.
------------------------------------------------------------------------- */
// export const Overlay = styled.div<OverlayProps>`
//   display: ${(p) => (p.menuOpen ? 'block' : 'none')};
//   position: fixed;
//   inset: 0; /* top: 0; right: 0; bottom: 0; left: 0 */
//   background-color: rgba(0, 0, 0, 0.4);
//   z-index: 999;
//   transition: opacity 0.3s ease;
// `

/* -------------------------------------------------------------------------
   MobileMenuIcon (Hamburger)
------------------------------------------------------------------------- */
export const MobileMenuIcon = styled.button`
  background: none;
  border: none;
  font-size: 4rem;
  margin: auto 0;
  width: fit-content;
  max-width: 4rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;

  /* Only visible on mobile. */
  display: inline-block;
  ${media.md`
    display: none;
  `}
`

/* -------------------------------------------------------------------------
   MobileCloseIcon (X button)
------------------------------------------------------------------------- */
export const MobileCloseIcon = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  text-align: right;
  margin: 1rem;
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.primary};
`

/* -------------------------------------------------------------------------
   ModalDropdown – for sub-items on desktop if needed
------------------------------------------------------------------------- */
export const ModalDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0.5rem;
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`
