import React from "react"
import styled from "styled-components"
import { Quote } from "../BachFlowers"
import { Helmet } from "react-helmet-async"

// --- HERO SECTION ---
const HeroSection = styled.section`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(223, 145, 98, 0.2), rgba(223, 145, 98, 0.4)),
    url("/img/hero-acupuncture.png") center/cover no-repeat;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  margin-top: 15vh;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
    margin-top: 10vh;
  }
`

const HeroContent = styled.div`
  max-width: 800px;

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  }

  p {
    color: white;
    font-size: 1.5rem;
    line-height: 1.8;
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

// --- CONTENT SECTIONS ---
const Section = styled.section`
  width: 100%;
  padding: 6rem 2rem;
  background: ${({ background }) => background || "white"};

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.7;
  }
`

const BulletList = styled.ul`
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 0.5rem;

    &:first-child {
      font-weight: bold;
    }
  }

  span {
    font-size: 1.5rem;

    margin-right: 10px;
    color: #d19166;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  height: 350px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    height: 280px;
  }
`

const CTAButton = styled.a`
  display: inline-block;
  background: #d19166;
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  margin-top: 2rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #b57b55;
    transform: translateY(-3px);
  }
`

// --- ACUPUNCTURE PAGE COMPONENT ---
const Acupuncture = () => {
  return (
    <>
      <Helmet>
        <title>
          Clinique Dö | Acupuncture à Rawdon – Soulagez vos douleurs,
          rétablissez votre équilibre
        </title>
        <meta
          name="description"
          content="Découvrez les bienfaits de l’acupuncture à la Clinique Dö à Rawdon. Soulagez vos douleurs, réduisez l’inflammation et rétablissez l’équilibre naturel de votre corps avec nos soins spécialisés."
        />
        <meta
          name="keywords"
          content="
        acupuncture rawdon, acupuncture lanaudière, soins thérapeutiques rawdon, bien-être rawdon, soulagement douleur rawdon, traitement inflammation rawdon, équilibre naturel rawdon
       "
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://cliniquedo.com/services/acupuncture"
        />
      </Helmet>

      {/* HERO */}
      <HeroSection>
        <HeroContent>
          <h1>L’Acupuncture : un traitement ciblé et efficace</h1>
          <p>
            Soulager la douleur | Réduire l’inflammation | Rétablir l’équilibre
            naturel du corps
          </p>
        </HeroContent>
      </HeroSection>

      {/* CONTENT SECTIONS */}
      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Pourquoi choisir l’acupuncture ?</h2>
              <p>
                L’acupuncture est une approche médicale reconnue pour traiter
                efficacement les douleurs musculo-squelettiques, les blessures
                sportives, les douleurs chroniques et l’inflammation.
                <br />
                <br />
                Basée sur des recherches modernes et des millénaires
                d’application, cette pratique stimule le système nerveux,
                favorise la circulation sanguine et libère des endorphines pour
                un soulagement durable.
              </p>
              <BulletList>
                <li>
                  <span>✅</span> Traitement personnalisé adapté à vos besoins
                </li>
                <li>
                  <span>✅</span> Pratique validée par des études scientifiques
                </li>
                <li>
                  <span>✅</span> Sans médicaments, sans effets secondaires
                  nocifs
                </li>
              </BulletList>
            </TextContent>
            <ImageWrapper>
              <img src="/img/acu1.png" alt="Acupuncture" />
            </ImageWrapper>
          </ContentBlock>
          <Quote>
            ""L'acupuncture est inscrite au patrimoine immatériel de l’UNESCO
            depuis 2010, témoignant de son efficacité et de son importance dans
            le soin des patients à travers le monde."
          </Quote>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Quels troubles peut-on traiter ?</h2>
              <BulletList>
                <li>
                  <span>🧠</span> Troubles du système nerveux
                </li>
                <li>✔ Insomnie, fatigue, épuisement</li>
                <li>✔ Anxiété, stress, dépression légère</li>
                <li>✔ Migraines et maux de tête</li>
                <li>✔ Hypotension ou hypertension</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>💥</span> Douleurs et inflammations
                </li>
                <li>✔ Maux de dos (lombaires, thoraciques, cervicales)</li>
                <li>✔ Sciatique, tendinite, bursite</li>
                <li>✔ Fibromyalgie, douleurs musculaires chroniques</li>
                <li>✔ Arthrite et arthrose</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>🫁</span> Troubles respiratoires
                </li>
                <li>✔ Asthme, bronchite, rhinites allergiques</li>
                <li>✔ Sinusite, toux persistante</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>🍽</span> Troubles digestifs
                </li>
                <li>✔ Ballonnements, reflux gastrique, brûlures d’estomac</li>
                <li>✔ Constipation, diarrhée, nausées</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>🩸</span> Troubles hormonaux et du système reproducteur
                </li>
                <li>✔ Douleurs menstruelles, syndrome prémenstruel</li>
                <li>✔ Symptômes de la ménopause, fertilité</li>
              </BulletList>
              <BulletList>
                <li>
                  <span>👂</span> Troubles auditifs et dermatologiques
                </li>
                <li>✔ Acouphènes, otites à répétition</li>
                <li>✔ Eczéma, psoriasis, urticaire</li>
              </BulletList>
            </TextContent>
            <ImageWrapper>
              <img src="/img/acu2.jpg" alt="Acupuncture Benefits" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Pourquoi choisir la Clinique Dö Rawdon ?</h2>
              <p>
                Nous combinons une approche pragmatique et une écoute attentive
                pour offrir des soins qui soulagent réellement vos douleurs et
                améliorent votre qualité de vie. Nos traitements sont adaptés à
                chaque patient, basés sur une évaluation précise de vos besoins
                et des techniques éprouvées.
              </p>
              <CTAButton href="/nous-joindre">
                Prenez rendez-vous dès maintenant →
              </CTAButton>
            </TextContent>
            <ImageWrapper>
              <img src="/img/rawdon-clinique.png" alt="Clinique Dö Rawdon" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <TextContent>
            <h2>Informations supplémentaires</h2>
            <p>
              ✔ Aiguilles stériles à usage unique seulement.
              <br />
              <br />✔ Les traitements d’acupuncture n’entrent pas en
              contre-indication avec votre médication mise en place.
            </p>
          </TextContent>
        </ContentWrapper>
      </Section>
    </>
  )
}

export default Acupuncture
