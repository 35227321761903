import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Logo from "@assets/Logo-jaune.jpg"

// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;

  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.primary},
    ${({ theme }) => theme.primaryLight}
  );
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  overflow: auto;

  @media (max-width: 768px) {
    align-items: flex-start;
    padding: 2rem 0rem;
    padding-bottom: 5rem;
  }
`

const ModalContent = styled.div`
  text-align: center;
  color: white;
  padding: 2rem;
  max-width: 90%;

  @media (max-width: 768px) {
    max-width: 95%;
  }
`

const ModalLogo = styled.img`
  max-width: 220px;
  height: auto;
  margin-bottom: 1.5rem;
`

const ModalHeading = styled.p`
  font-size: 3rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: white;
`

const ModalSubText = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.9);

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1.4rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryDark};
  }
`

export const EntryModal: React.FC = () => {
  const [showModal, setShowModal] = useState(() => {
    return sessionStorage.getItem("modalDismissed") !== "true"
  })

  useEffect(() => {
    if (!showModal) {
      sessionStorage.setItem("modalDismissed", "true")
    }
  }, [showModal])

  if (!showModal) return null

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalLogo src={Logo} alt="Logo" />
        <ModalHeading>Clinique dö s'est refait une beauté</ModalHeading>
        <ModalSubText>
          Bienvenue dans l'univers raffiné de Clinique Do, où élégance et
          expertise se conjuguent pour sublimer votre bien-être. Découvrez un
          espace transformé, pensé pour vous offrir une expérience inégalée.
        </ModalSubText>
        <ModalButton onClick={() => setShowModal(false)}>
          Entrez ici
        </ModalButton>
      </ModalContent>
    </ModalOverlay>
  )
}
