import { Helmet } from "react-helmet-async"
import styled from "styled-components"

// --- HERO SECTION ---
const HeroSection = styled.section`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(223, 145, 98, 0.2), rgba(223, 145, 98, 0.4)),
    url("/img/hero-services.jpg") center/cover no-repeat;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  margin-top: 15vh;

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
    margin-top: 10vh;
  }
`

const HeroContent = styled.div`
  max-width: 800px;

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  }

  p {
    color: white;
    font-size: 1.5rem;
    line-height: 1.8;
    opacity: 0.9;
    font-weight: 500;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

// --- CONTENT SECTIONS ---
const Section = styled.section`
  width: 100%;
  padding: 6rem 2rem;
  background: ${({ background }) => background || "white"};

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.7;
  }
`

const BulletList = styled.ul`
  margin-top: 1rem;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #d19166;
  }

  @media (max-width: 768px) {
    margin: 0 1rem;
    margin-top: 2.5rem;
    li {
      /* font-size: 1rem; */
      text-align: left;
      margin: 1rem 0;
    }
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  height: 350px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    height: 280px;
  }
`

const CTAButton = styled.a`
  display: inline-block;
  background: #d19166;
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  margin-top: 2rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #b57b55;
    transform: translateY(-3px);
  }
`

// --- SERVICES PAGE COMPONENT ---
const Services = () => {
  return (
    <>
      <Helmet>
        <title>
          Clinique Dö | Services de Santé à Rawdon – Acupuncture, Ostéopathie,
          Massothérapie
        </title>
        <meta
          name="description"
          content="Découvrez les services de santé offerts par Clinique Dö à Rawdon. Acupuncture, ostéopathie, massothérapie et fasciathérapie pour votre bien-être. Prenez rendez-vous dès maintenant!"
        />
        <meta
          name="keywords"
          content="
      services de santé rawdon, acupuncture rawdon, ostéopathie rawdon, massothérapie rawdon, fasciathérapie rawdon, clinique dö services, soins thérapeutiques rawdon, bien-être rawdon, acupuncture lanaudière, ostéopathie lanaudière, massothérapie lanaudière, fasciathérapie lanaudière
    "
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://cliniquedo.com/services" />
      </Helmet>

      {/* HERO */}
      <HeroSection>
        <HeroContent>
          <h1>Votre bien-être, notre priorité</h1>
          <p>
            Nous offrons une gamme complète de services de santé pour répondre à
            vos besoins spécifiques et améliorer votre qualité de vie.
          </p>
        </HeroContent>
      </HeroSection>

      {/* CONTENT SECTIONS */}
      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Acupuncture</h2>
              <BulletList>
                <li>
                  <span>📍</span> Techniques modernes et traditionnelles
                </li>
                <li>
                  <span>📍</span> Douleurs articulaires, inflammations, stress
                </li>
                <li>
                  <span>📍</span> Remboursé par plusieurs assurances
                </li>
              </BulletList>
              <CTAButton href="/services/acupuncture">
                Découvrir l’acupuncture →
              </CTAButton>
            </TextContent>
            <ImageWrapper>
              <img src="/img/acupuncture-session.jpg" alt="Acupuncture" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Massothérapie & Fasciathérapie</h2>
              <BulletList>
                <li>
                  <span>📍</span> Relaxation, soulagement musculaire
                </li>
                <li>
                  <span>📍</span> Thérapeutique, sportif, prénatal
                </li>
                <li>
                  <span>📍</span> Amélioration de la circulation sanguine
                </li>
              </BulletList>
              <CTAButton href="/services/fasciatherapie-massotherapie">
                En savoir plus →
              </CTAButton>
            </TextContent>
            <ImageWrapper>
              <img
                src="/img/massotherapie.jpg"
                alt="Massothérapie et Fasciathérapie"
              />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="#f9f5f0">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Ostéopathie</h2>
              <BulletList>
                <li>
                  <span>📍</span> Rééducation posturale, réadaptation
                </li>
                <li>
                  <span>📍</span> Troubles digestifs, céphalées, douleurs
                  chroniques
                </li>
                <li>
                  <span>📍</span> Approche manuelle et précise
                </li>
              </BulletList>
              <CTAButton href="/services/osteopathie">
                Consulter un ostéopathe →
              </CTAButton>
            </TextContent>
            <ImageWrapper>
              <img src="/img/osteopathie.jpg" alt="Ostéopathie" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>

      <Section background="white">
        <ContentWrapper>
          <ContentBlock>
            <TextContent>
              <h2>Fleurs de Bach</h2>
              <BulletList>
                <li>
                  <span>📍</span> Réduire le stress et l’anxiété
                </li>
                <li>
                  <span>📍</span> Adoucir des peurs et des phobies
                </li>
                <li>
                  <span>📍</span> Adresser des difficultés relationnelles
                </li>
              </BulletList>
              <CTAButton href="/services/fleurs-de-bach">
                Consulter une conseillère →
              </CTAButton>
            </TextContent>
            <ImageWrapper>
              <img src="/img/fleurs-de-bach1.jpg" alt="Fleurs de Bach" />
            </ImageWrapper>
          </ContentBlock>
        </ContentWrapper>
      </Section>
    </>
  )
}

export default Services
