export interface DropdownItem {
  label: string
  to: string
  icon?: React.ReactNode
}

export interface DesktopNavItem {
  label: string
  to: string
  childItems?: DropdownItem[]
}

// Some items have subItems, some do not.
export const desktopNavItems: DesktopNavItem[] = [
  { label: "Accueil", to: "/" },
  { label: "À propos", to: "/a-propos" },
  {
    label: "Services",
    to: "/services",
    // When hovered, the subnav bar will display these items.
    // childItems: [
    //   { label: 'Gestion Locative', to: '/services/gestion-locative' },
    //   { label: 'Entretien', to: '/services/entretien' },
    // ],
  },
  { label: "Contact", to: "/nous-joindre" },
  { label: "Accès client", to: "/app" },
]

export const secondaryNavItems: DesktopNavItem[] = [
  { label: "Acupuncture", to: "/services/acupuncture" },
  { label: "Ostéopathie", to: "/services/osteopathie" },
  { label: "Massothérapie", to: "/services/fasciatherapie-massotherapie" },
  {
    label: "et bien plus..",
    to: "/services",
    childItems: [
      { label: "Fleurs de Bach", to: "/services/fleurs-de-bach" },
      // { label: 'Huiles essentiels', to: '/services/fleurs-de-bach' },
    ],
  },
  { label: "Douleurs", to: "/services/acupuncture" },
  { label: "Stress & anxiété", to: "/services/acupuncture" },
  { label: "Blessures", to: "/services/acupuncture" },
  { label: "Allergies", to: "/services/acupuncture" },
]
