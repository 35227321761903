import React, { useState } from "react"
import { NavItemLink, ModalDropdown } from "./Style"
import styled from "styled-components"

export interface DropdownItem {
  label: string
  to: string
  icon?: React.ReactNode
}

export interface NavItem {
  label: string
  to: string
  childItems?: DropdownItem[]
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`

// A simple caret icon that rotates when open.
const CaretIcon = styled.span<{ isOpen: boolean }>`
  margin-left: 0.25rem;
  display: inline-block;

  transition: transform 0.2s ease;
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
`

interface DropdownNavItemProps {
  item: NavItem
}

export const DropdownNavItem: React.FC<DropdownNavItemProps> = ({ item }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleMouseEnter = () => setDropdownOpen(true)
  const handleMouseLeave = () => setDropdownOpen(false)

  return (
    <DropdownContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <NavItemLink to={item.to}>
        {item.label}
        {item.childItems && <CaretIcon isOpen={dropdownOpen}>▼</CaretIcon>}
      </NavItemLink>
      {item.childItems && dropdownOpen && (
        <ModalDropdown>
          {item.childItems.map((child, index) => (
            <NavItemLink key={index} to={child.to}>
              {child.icon && <span>{child.icon}</span>}
              {child.label}
            </NavItemLink>
          ))}
        </ModalDropdown>
      )}
    </DropdownContainer>
  )
}
