import { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import Checkmark from "@assets/icons/Checkmark.svg"

// Container for the entire section
const Layout = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 18vh;
  min-height: 82vh;
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 2rem; /* Add some spacing between text and image on mobile */
    margin-top: 20vh; /* Slightly reduce top margin on mobile */
    height: 100vh; /* Let height adapt to content */
    padding-bottom: 2rem; /* Extra space at the bottom for mobile */
  }
`

// Left text block
const TextBlock = styled.div`
  flex: 1;
  padding-right: 2rem;

  h1 {
    font-size: 3rem;
    font-weight: 500;
    color: #525252;
    margin: 0;
  }

  .highlight {
    color: #d19166;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding-right: 0;
    padding: 0 0.75rem;
    margin: auto;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }

    /* Optional: center the text on mobile */
    text-align: center;
  }
`

// Bullet points
const List = styled.ul`
  margin-top: 1rem;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #666;
  }

  svg {
    margin-right: 0.5rem;
    color: #d19166;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    /* Keep bullets easy to read on mobile (center or narrower) */
    margin: 0 1rem;
    li {
      font-size: 0.9rem;
      text-align: left;
      margin: 2rem 0;

      span {
        font-size: 0.9rem;

        margin-left: 0.4rem;
        width: 100%;
      }
    }
    /* max-width: 300px; */
  }
`

// Image Wrapper to prevent layout shifts
const ImageContainer = styled.div`
  position: relative;
  flex: 1;
  max-width: 400px;
  height: 75vh;
  overflow: hidden;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 100%; /* Use full width on mobile */
    max-width: 100%; /* Override max-width: 400px */
    height: 30vh; /* Fixed but comfortable height for mobile */
    margin: 0 auto;
  }
`

// Right image section with dynamic text overlay
const ImageBlock = styled(motion.div)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    filter: grayscale(100%);
  }

  .overlay-text {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%); /* Keep text centered */
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    padding: 8px 12px;
    font-weight: bold;
    color: #d19166;
    font-size: 1.2rem;
    border-radius: 8px;

    @media (max-width: 768px) {
      font-size: 1rem;
      padding: 6px 10px;
    }
  }

  @media (max-width: 768px) {
  }
`

// Image Data
const images = [
  { src: "/img/acupuncture-session.jpg", label: "ACUPUNCTURE" },
  { src: "/img/massotherapie2.jpg", label: "MASSOTHÉRAPIE" },
  { src: "/img/osteopathie.jpg", label: "OSTÉOPATHIE" },
]

const Hero = () => {
  const [index, setIndex] = useState(0)

  const isMobile = window.innerWidth <= 768

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 5000) // Changes every 5 seconds

    return () => clearInterval(interval)
  }, [])

  return (
    <Layout>
      {/* Left Text Content */}
      <TextBlock>
        <h1>
          Douleur, inflammation, stress... {!isMobile && <br />}
          traitons la cause,{" "}
          <span className="highlight">pas seulement les symptômes.</span>
        </h1>
        <List>
          <li>
            <Checkmark /> <strong>Douleurs chroniques et aiguës</strong>{" "}
            <span> – Sciatique, lombalgies, tendinites, migraines...</span>
          </li>
          <li>
            <Checkmark /> <strong>Récupération et performance</strong>{" "}
            <span>
              {" "}
              – Accompagnement des sportifs et optimisation du rétablissement.
            </span>
          </li>
          <li>
            <Checkmark /> <strong>Stress et troubles fonctionnels</strong>{" "}
            <span> – Insomnie, fatigue, troubles digestifs et hormonaux.</span>
          </li>
        </List>
      </TextBlock>

      {/* Right Image Container */}
      <ImageContainer>
        <AnimatePresence mode="wait">
          <ImageBlock
            key={index}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
          >
            <img src={images[index].src} alt={images[index].label} />
            <motion.div
              className="overlay-text"
              initial={{ opacity: 0, x: "-50%", y: 15 }}
              animate={{ opacity: 1, x: "-50%", y: 0 }}
              exit={{ opacity: 0, x: "-50%", y: -15 }}
              transition={{ delay: 0.5, duration: 0.5, ease: "easeOut" }}
            >
              {images[index].label}
            </motion.div>
          </ImageBlock>
        </AnimatePresence>
      </ImageContainer>
    </Layout>
  )
}

export default Hero
