import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import useAuthStore from "../../../../features/auth/authStore.client"
import logger from "@utils/clientLogger"

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

// Overall page layout with two sections: left (image) and right (form)
const PageWrapper = styled.div`
  display: flex;
  min-height: 85.5vh;
  margin-top: 14.5vh;
  font-family: "Arial", sans-serif;
  background: ${({ theme }) => theme.backgroundPrimary || "#1a1a1a"};
  color: ${({ theme }) => theme.primaryText || "#ffffff"};

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

// Left section: full-height background image with an overlay
const LeftSection = styled.div`
  flex: 1;
  background: url("/img/roches-equilibre.jpg") center/cover no-repeat;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(223, 145, 98, 0.1);
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
    flex: unset;
  }
`

// Right section: contains the login form and animates in
const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  animation: ${fadeIn} 1s ease-out;
`

// Form container with translucent backdrop and subtle shadow
const FormWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  background: ${({ theme }) =>
    theme.backgroundSecondary || "rgba(255, 255, 255, 0.1)"};
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  text-align: center;
`

// Title for the form
const FormTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.primaryDark || "#007bff"};
`

// Styled input fields
const Input = styled.input`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.borderColor || "#ccc"};
  border-radius: 8px;
  background: ${({ theme }) => theme.backgroundPrimary || "#fff"};
  color: ${({ theme }) => theme.primaryText || "#000"};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary || "#007bff"};
  }
`

// Animated submit button with hover/tap effects
const Button = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.white || "#fff"};
  background: ${({ theme }) => theme.primary || "#007bff"};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.primaryDark || "#0056b3"};
  }
`

// Text for additional links below the form
const LinkText = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.textSecondary || "#ccc"};

  a {
    color: ${({ theme }) => theme.primary || "#007bff"};
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`

// Logo container styled as a link (can be positioned as desired)
const Logo2 = styled.a`
  margin-top: 2rem;
  display: inline-block;

  img,
  svg {
    width: 80px;
  }
`

const ClientLogin = () => {
  const navigate = useNavigate()
  const { login, error, loading, setLoading, setError } = useAuthStore(
    (state) => ({
      login: state.login,
      error: state.error,
      loading: state.loading,
      setLoading: state.setLoading,
      setError: state.setError,
    }),
  )

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await login(email, password)
      navigate("/app")
    } catch (err) {
      if (err instanceof Error) {
        logger.error("Login failed: " + err.message, { email })
      } else {
        logger.error("Login failed with unknown error", { error: err })
      }
    }
  }

  useEffect(() => {
    setLoading(false)
    setError("")
  }, [setLoading, setError])

  return (
    <PageWrapper>
      <Helmet>
        <title>Connexion Client | Clinique dö</title>
        <meta name="description" content="" />
      </Helmet>

      {/* Left Section with Background Image */}
      <LeftSection />

      {/* Right Section with Login Form */}
      <RightSection>
        <FormWrapper>
          <FormTitle>Connexion client</FormTitle>
          <form onSubmit={handleSubmit}>
            <Input
              type="email"
              placeholder="Adresse e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input
              type="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button
              type="submit"
              disabled={loading}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {loading ? "Connexion en cours..." : "Se connecter"}
            </Button>
            {error && (
              <p style={{ color: "red", marginTop: "1rem" }}>
                {typeof error === "string" ? error : error}
              </p>
            )}
          </form>
          <LinkText>
            Mot de passe oublié ? <a href="/forgot-password">Réinitialiser</a>
          </LinkText>
          <LinkText>
            Pas encore de compte ? <a href="/signup">Créer un compte</a>
          </LinkText>
        </FormWrapper>
        <Logo2 href="/">{/* <SvgLogo /> */}</Logo2>
      </RightSection>
    </PageWrapper>
  )
}

export default ClientLogin
