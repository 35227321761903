import React, { useRef, useState, useEffect } from "react"
import { useScroll, useTransform, motion } from "framer-motion"

// ====== Desktop styling/components (from your `./Style` file) ======
// Desktop layout is completely unchanged
import {
  Section,
  PinnedContainer,
  LeftCol,
  RightCol,
  Heading,
  ImageGrid,
  ImageWrapper,
} from "./Style"

// Example image
import professional1 from "@assets/notre-equipe.png"
const images = [professional1]

/* -----------------------------------------
   📌 1) TeamSectionDesktop (Original, Unchanged)
----------------------------------------- */
function TeamSectionDesktop() {
  const sectionRef = useRef(null)

  // 300vh scroll range, pinned container
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start start", "end end"],
  })

  // Text fade/slide
  const text1Opacity = useTransform(
    scrollYProgress,
    [0.0, 0.03, 0.2, 0.23],
    [0, 1, 1, 0],
  )
  const text1Y = useTransform(scrollYProgress, [0.0, 0.2], [50, -50])
  const text2Opacity = useTransform(
    scrollYProgress,
    [0.3, 0.4, 0.6, 0.7],
    [0, 1, 1, 0],
  )
  const text2Y = useTransform(scrollYProgress, [0.3, 0.6], [50, -50])

  // Images fade/scale
  const imagesOpacity = useTransform(scrollYProgress, [0, 0.0], [0, 1])
  const imagesScale = useTransform(scrollYProgress, [0, 0.03], [0.8, 1])

  return (
    <Section ref={sectionRef}>
      <PinnedContainer>
        <LeftCol>
          <motion.div style={{ y: text1Y, opacity: text1Opacity }}>
            <Heading>Une équipe de professionnels dévoués</Heading>
          </motion.div>

          <div style={{ height: "4rem" }} />

          <motion.div style={{ y: text2Y, opacity: text2Opacity }}>
            <Heading>
              Des soins de haute qualité, guidés par la compassion et
              l&#39;innovation
            </Heading>
          </motion.div>
        </LeftCol>

        <RightCol>
          <ImageGrid style={{ opacity: imagesOpacity, scale: imagesScale }}>
            {images.map((image, i) => (
              <ImageWrapper
                key={i}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.7, delay: i * 0.15 }}
              >
                <img src={image} alt={`Pro ${i + 1}`} />
              </ImageWrapper>
            ))}
          </ImageGrid>
        </RightCol>
      </PinnedContainer>
    </Section>
  )
}

/* -----------------------------------------
   📌 2) TeamSectionMobile (Simple Layout)
----------------------------------------- */
function TeamSectionMobile() {
  return (
    <div style={{ width: "100%", padding: "2rem 1rem", marginTop: "10vh" }}>
      {/* Stacked text */}
      <div style={{ marginBottom: "2rem", textAlign: "center" }}>
        <h2 style={{ fontSize: "2.5rem", margin: 0 }}>
          Une équipe de professionnels dévoués
        </h2>
      </div>

      <div style={{ marginBottom: "2rem", textAlign: "center" }}>
        <h2 style={{ fontSize: "1.4rem", margin: 0 }}>
          Des soins de haute qualité, guidés par la compassion et
          l&#39;innovation
        </h2>
      </div>

      {/* Images centered */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        {images.map((image, i) => (
          <div
            key={i}
            style={{
              borderRadius: "8px",
              overflow: "hidden",
              width: "100%",
              maxWidth: "400px",
              margin: "0 auto",
            }}
          >
            <img
              src={image}
              alt={`Pro ${i + 1}`}
              style={{ width: "100%", display: "block", objectFit: "cover" }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

/* -----------------------------------------
   📌 3) Main Component (Auto Switch Based on Screen Width)
----------------------------------------- */
export default function TeamSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  // 📌 Update on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // 🎯 Render Desktop or Mobile Version
  return isMobile ? <TeamSectionMobile /> : <TeamSectionDesktop />
}
