import React from "react"
import styled from "styled-components"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #fdfaf7;
  background-color: white;

  @media (min-width: 768px) {
    padding: 3rem 1rem;
  }
`

const SectionContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 4rem;
  padding-bottom: 2rem;
  /* background-color: #fdfaf7; */

  background: url("/img/acupuncture-session.jpg") center/cover no-repeat;
  max-width: 1450px;
  border-radius: 30px;
  margin: 0 auto;

  > * {
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding: 0rem 1rem;
  }
`

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(136, 115, 101, 0.8);
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 0;
  border-radius: 30px;
  filter: grayscale(100%);
`

const Heading = styled.h2`
  font-size: 3rem;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryLight};
  text-align: left;
  margin-right: auto;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    text-align: center;
    margin-right: unset;
  }
`

const HorizontalBar = styled.div`
  width: 60px;
  height: 4px;
  background: ${({ theme }) => theme.primary};
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 50px;
  }
`

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 900px;
`

const ServiceCard = styled.div`
  display: flex;
  background: white;
  background-color: rgba(247, 247, 247, 0.9);
  background-color: ${({ theme }) => theme.white};
  backdrop-filter: blur(10px);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease-in-out;
  align-items: center;
  gap: 1.5rem;

  &:hover {
    transform: translateY(-3px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1.2rem;
  }
`

const Content = styled.div`
  flex: 1;
`

const ServiceTitle = styled.h3`
  font-size: 2.05rem;
  /* font-weight: bold; */
  color: ${({ theme }) => theme.primary};
  margin: 0.05rem 0;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`

const Description = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin-bottom: 0.8rem;
`

const Highlight = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
`

const ReadMoreLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  svg {
    margin-left: 5px;
  }

  &:hover {
    color: #814d37;
  }
`

const LogoContainer = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto;

  img {
    max-width: 100%;
    /* max-height: 60px; */
  }

  @media (max-width: 768px) {
    width: 100px;
  }
`

const services = [
  {
    title: "Acupuncture – Soulager et rétablir l’équilibre",
    description:
      "Traitement ciblé pour : douleurs chroniques, inflammations, troubles digestifs, stress, insomnie, migraines.",
    highlight: "Comment ça marche ?",
    details: (
      <>
        L'acupuncture stimule des points spécifiques du corps pour activer ses
        mécanismes naturels. <br />
        <br /> Elle soulage entre autre la douleur (effet analgésique), apaise
        le stress et favorise le sommeil (effet sédatif), régule le système
        immunitaire (effet pro-immunitaire) et détend les muscles (effet
        musculaire).
      </>
    ),
    link: "/services/acupuncture",
    logo: "/img/logos/logo-ordre-acupuncteurs-du-quebec.svg",
    sideIcon: "/img/icons/Needles.svg",
  },
  {
    title: "Massothérapie – Détente et récupération musculaire",
    description:
      "Traitement adapté pour : douleurs musculaires, tensions, gestion du stress, récupération après sport.",
    highlight: "Pourquoi ça fonctionne ?",
    details:
      "Améliore la circulation sanguine, détend les muscles et réduit le stress.",
    link: "/services/fasciatherapie-massotherapie",
    // logo: '/massotherapy-logo.png',
    sideIcon: "/massotherapy-icon.png",
  },
  {
    title: "Ostéopathie – Mobilité et réadaptation",
    description:
      "Techniques manuelles précises pour améliorer la posture, la mobilité et soulager la douleur.",
    highlight: "Pourquoi choisir l’ostéopathie ?",
    details:
      "Approche globale du corps, efficace et complémentaire à la médecine traditionnelle.",
    link: "/services/osteopathie",
    // logo: '/osteopathy-logo.png',
    sideIcon: "/osteopathy-icon.png",
  },
]

const logos = [
  "img/logos/logo-saaq-blanc.png", // Replace with actual logo paths
  "img/logos/cavac.png",
  "img/logos/cnesst.png",
]

const ServicesOverview = () => {
  return (
    <Layout>
      <SectionContainer>
        <Overlay />
        <Heading>Nos services</Heading>
        <ServiceContainer>
          {services.map((service, index) => (
            <ServiceCard key={index}>
              <Content>
                <ServiceTitle>{service.title}</ServiceTitle>
                <HorizontalBar />
                <Description>{service.description}</Description>
                <Highlight>{service.highlight}</Highlight>
                <Description>{service.details}</Description>
                <ReadMoreLink href={service.link}>
                  En savoir plus{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.354 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L9.293 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M11 8a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h6.5a.5.5 0 0 1 .5.5z"
                    />
                  </svg>
                </ReadMoreLink>
              </Content>
              {/* <LogoContainer>{service.logo && <img src={service.logo} alt='Service Logo' />}</LogoContainer>
              <SideIconContainer>
                <img src={service.sideIcon} alt='Service Icon' />
              </SideIconContainer> */}
            </ServiceCard>
          ))}

          <LogoContainer>
            {logos.map((logo, index) => (
              <img key={index} src={logo} alt="Logo" />
            ))}
          </LogoContainer>
        </ServiceContainer>
      </SectionContainer>
    </Layout>
  )
}

export default ServicesOverview
